.container {
    width: 100%;
    margin-top: 36px;
    min-height: 866px;
    background-color: var(--bg-white);
    display: flex;
    justify-content: space-between;
}

.left_cnt {
    width: 20%;
    padding: 12px 6px;
    border-right: 1px solid var(--bg-color);
}

.list_link {
    display: flex;
    flex-direction: column;
}

.link_item_cnt {
    margin-bottom: 4px;
}

.link_item {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 6px;
    transition: var(--trans);
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #374957;
}

.link_item_act {
    background-color: var(--bg-color);
}

.link_item:hover {
    background-color: var(--bg-color);
    transition: var(--trans);
}

.link_item_icon {
    width: 34px;
    height: 34px;
    padding: 4px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3e6ea;
    margin-right: 8px;
    transition: var(--trans);
}

.link_item_icon img {
    width: 20px;
    height: 20px;
}

.right_cnt {
    width: 80%;
    padding: 12px 12px 12px 6px;
    position: relative;
}

.result_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 12px;
}

.result_body {
    min-height: calc(100vh + 74px);
}

.load_bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.load_bottom button {
    background-color: transparent;
    color: var(--purple);
}

/* filter_container */
.filter_container {
    position: absolute;
    z-index: 2;
    width: calc(25% - 12px);
    margin-left: calc(-25%);
    margin-top: 152px;
}
/* // */
.bottom{
    position: fixed;
    bottom: 24px;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
   justify-content: center;
}
.bottom_btn{
    color: var(--purple);
    background-color: var(--bg-white);
    box-shadow: var(--box-shadow);
}
.bottom_btn:hover{
    opacity: 1;
}
.productableGroupItem{
    padding: 12px;
}


@media only screen and (max-width: 1023px) {
    .head_wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 101;
        background-color: var(--bg-white);
        box-shadow: var(--box-shadow);
    }

    .container {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
    }

    .left_cnt {
        width: 100%;
        padding: 0px 12px;
    }

    .list_link {
        background-color: var(--bg-color);
        padding: 8px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .link_item_cnt {
        margin: 0px;
        width: 30%;
    }

    .link_item {
        display: flex;
        justify-content: center;
        margin: 0px;
        border-radius: 16px;
    }

    .link_item_act {
        background-color: var(--purple) !important;
    }

    .link_item_act span {
        color: var(--bg-white);
    }

    .link_item_icon {
        display: none;
    }

    .right_cnt {
        width: 100%;
        padding: 12px;
    }

    .result_list {
        grid-template-columns: repeat(2, 1fr);
    }
    .result_list_service{
        grid-template-columns: repeat(1, 1fr);
    }

    .result_list_org {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
    }

    .result_list_item_org {
        height: 112px;
        padding: 6px 0px;
    }

    /* filter */
    .filter_container {
        background-color: var(--bg-white);
        width: calc(100% - 24px);
        margin: 0px;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filter_right {
        width: calc(66.66% - 6px);
    }

    .filter_left {
        width: calc(33.33% - 6px);
    }

    .filter_btn {
        padding: 9px;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        background-color: var(--grey_2);
        color: var(--text-black);
    }

    .filter_btn img {
        margin: 0px;
        margin-left: 6px;
    }

    .result_body {
        padding-top: 54px;
    }

    .filter_cnt_mt {
        padding: 12px;
        width: 100%;
        height: fit-content;
        max-height: 75vh;
        overflow-y: scroll;
        background-color: var(--bg-white);
    }
    /* service group item */
    .result_list_group {
        grid-template-columns: repeat(1, 1fr);
        grid-gap:0px
    }

    .item_org_group {
        padding: 12px 0px 0px 0px;
        border-bottom: solid 1px var(--bg-color);
    }

    .group_org {
        display: flex;
        justify-content: space-between;
    }

    .group_org_img {
        position: relative;
        width: 56px;
        height: 56px;
        background-color: var(--bg-color);
        border-radius: 8px;
        border: solid 1px var(--bg-color);
    }

    .group_org_img img {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    .group_org_detail {
        width: calc(100% - 66px);
    }

    .group_org_detail_name {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
    }

    .group_org_detail_address {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .group_org_detail_rate,
    .rate_item {
        display: flex;
        align-items: center;
    }
    .group_org_detail_rate{
        margin-top: 4px;
    }

    .rate_item {
        margin-right: 10px;
    }

    .rate_item img {
        width: 12px;
        height: 12px;
        margin-right: 2px;
    }

    .rate_item span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    .group_org_services {
        margin-top: 12px;
    }

    .gr_service_link {
        padding: 10px 0px;
        border-top: solid 1px var(--bg-color);
        display: flex;
        justify-content: space-around;
    }

    .gr_service_img {
        width: 41px;
        height: 41px;
        position: relative;
        border-radius: 8px;
        background-color: var(--bg-color);
    }

    .gr_service_img img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 8px;
    }

    .gr_service_detail {
        width: calc(100% - 49px);
    }

    .gr_service_detail_name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .gr_service_price {
        display: flex;
        align-items: center;
    }

    .gr_service_price span:first-child {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    }

    .gr_service_price span:nth-child(2) {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-left: 4px;
        text-decoration: line-through;
    }
    .bottom{
        display: none;
    }
    .productableGroupItem{
        padding: 0px;
    }
}