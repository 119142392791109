.head {
    display: none;
}

.container {
    width: 100%;
    margin-top: 36px;
    background-color: var(--bg-white);
    padding: 24px;
}

.container_head {
    display: flex;
    justify-content: space-between;
}

.container_head_img_slide {
    width: 450px;
    /* overflow-x: hidden; */
    margin-bottom: 12px;
}

.container_head_slide {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--bg-color);
    position: relative;
}

.container_head_page {
    position: absolute;
    z-index: 8;
    background-color: #43434380;
    bottom: 12px;
    right: 12px;
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--white);
    font-size: 13px;
    font-weight: 600;
}

.side_item {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    cursor: pointer;
}

.container_head_thumb {
    margin-top: 12px;
    width: 100%;
}

.container_head_small {
    height: 90px;
    grid-gap: 0px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.side_item_thumb {
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    padding: 6px;
}

.thumb_img_cnt {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;
    border: solid 1px transparent;
    cursor: pointer;
}

.thumb_act {
    border: solid 1px var(--text-orange);
}

.thumb_img_cnt img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
    border-radius: 4px;
}

.side_item_video {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--bg-color);
    position: relative;
    cursor: pointer;
}

.video_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.container_head_thumb button {
    padding: 0px;
    background-color: var(--purple);
    position: absolute;
    z-index: 2;
    width: 20px;
    height: 20px;
}

.container_head_thumb button::before {
    position: absolute;
    font-size: 24px;
    width: 20px;
    height: 20px;
    top: -1px;
    left: -2px;
}

.container_head_thumb button:first-child {
    margin-left: 20px;
}

.container_head_thumb button:nth-child(3) {
    margin-right: 20px;
}

/* ------------ */

.container_head_right {
    width: calc(100% - 474px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail_cate {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.detail_cate span {
    margin-left: 2px;
    color: var(--purple);
    font-weight: 600;
    cursor: pointer;
    opacity: 0.7;
    transition: var(--trans);
}

.detail_cate span:hover {
    opacity: 1;
    transition: var(--trans);
}

.detail_name {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin: 8px 0px;
}

.duration,
.duration_item {
    display: flex;
    align-items: center;
}

.duration_item {
    margin-right: 20px;
}

.duration_item_text {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: #8C8C8C;
    margin-left: 4px;
}

.detail_buy {
    display: flex;
    flex-direction: column-reverse;
}

.detail_price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.detail_price_left {
    display: flex;
    align-items: center;
    margin: 12px 0px;
}

.price_percent {
    padding: 6px;
    background-color: var(--text-orange);
    color: var(--bg-white);
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    border-radius: 6px;
}

.price {
    margin-left: 6px;
    display: flex;
    align-items: center;
}

.right_btn_share {
    display: none;
}

.price span:first-child {
    color: var(--red-cl);
    font-size: 28px;
    font-weight: 700;
}

.price span:nth-child(2) {
    color: #808089;
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    text-decoration: line-through;
}

.detail_price_right {
    display: flex;
    align-items: center;
}

.right_btn {
    padding: 8px;
    background-color: var(--bg-color);
    border-radius: 100%;
}

.detail_rate {
    margin-top: 6px;
}

.detail_rate,
.detail_rate_item {
    display: flex;
    align-items: center;
}

.detail_rate_item {
    padding: 0px 6px;
}

.detail_rate_item_count {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.detail_rate_icon {
    margin-left: 4px;
    width: 18px;
    height: 18px;
}

.detail_org {
    width: 100%;
    padding: 16px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px var(--bg-color);
    border-radius: 8px;
}

.detail_org_left {
    width: calc(100% - 122px);
    display: flex;
}

.org_avatar {
    width: 74px;
    height: 74px;
    background-color: var(--bg-color);
    border-radius: 100%;
    position: relative;
}

.org_avatar_img {
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 100%;
    position: absolute;
}

.org_avatar_icon {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
}

.org_detail {
    width: calc(100% - 110px);
    padding-left: 10px;
}

.detail_org_right {
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail_org_right_btn {
    padding: 5px 20px;
    border: solid 1px var(--bg-purple);
    background-color: var(--bg-purple);
    color: var(--white);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border-radius: 6px;
    width: 100%;
    display: block;
    text-align: center;
}

.detail_org_right_btn:nth-child(2) {
    background-color: var(--white);
}

.detail_org_right_btn:nth-child(2) {
    background-color: var(--white);
}

.detail_org_link {
    margin-top: 4px;
    color: var(--red-cl);
    border: solid 1px var(--red-cl);
}

.org_name {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}

.org_address {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
    margin-top: 6px;
}

.org_distance {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.org_distance img {
    width: 14px;
    height: 14px;
}

.org_distance span {
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    margin-left: 4px;
}

.detail_cart {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.detail_quantity_title {
    font-weight: 700;
}

.detail_quantity_calc {
    display: flex;
    align-items: center;
    margin-top: 14px;
}

.detail_quantity_btn {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    padding: 6px;
    width: 32px;
    border-radius: 4px;
    background-color: var(--pr-green);
}

.detail_quantity_btn:first-child {
    color: var(--text-orange);
    background-color: var(--bg-color);
}

.quantity {
    width: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}

.add_cart {
    display: flex;
    align-items: center;
}

.add_cart_btn {
    background-color: var(--pr-green);
    border-radius: 6px;
    margin-left: 12px;
}

.add_cart_btn:last-child {
    background-color: var(--bg-purple);
}

.detail_dis {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: var(--red-cl);
}

.container_desc {
    margin-top: 24px;
}

.detail_sticky_cnt {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 20%;
    margin-left: 80%;
    /* height: 100px; */
    background-color: var(--white);
    padding: 12px;
}

.detail_sticky_title {
    font-size: 18px;
    font-weight: 500;
}

.sticky_item {
    margin-top: 8px;
}

.sticky_item_img {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--bg-color);
    position: relative;
}

.sticky_item_percent {
    background-color: var(--text-orange);
    border-radius: 0 0 0 8px;
    color: var(--bg-white);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 5px;
    position: absolute;
    right: 0;
    z-index: 10;
}

.sticky_item_img img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sticky_item_de {
    padding: 8px
}

.sticky_item_de_name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--text-black);
    display: -webkit-box;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    min-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.sticky_item_de_price {
    margin-top: 4px;
}

.sticky_item_de_price p:first-child {
    color: var(--red-2);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}

.sticky_item_de_price p:nth-child(2) {
    color: var(--text-hover);
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-decoration: line-through;
}

.sticky_item_bot {
    display: flex;
    justify-content: flex-end;
}

.sticky_item_bot button {
    border-radius: 4px;
    width: 100%;
    background-color: var(--pr-green);
}

.desc_body_cnt {
    margin-top: -388.5px;
    width: calc(80% - 12px);
    background-color: var(--white);
    padding: 24px;
    min-height: 388.5px;
}

.container_desc_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--bg-purple);
}

.container_desc_content {
    margin-top: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: fit-content;
    max-height: 500px;
    overflow: hidden;
    color: #434343;
    position: relative;
}

.container_desc_content_full {
    height: max-content;
    max-height: unset;
}

.container_desc_content_txt p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -35px;
}

.container_desc_content_txt p:first-child {
    margin-top: 0px;
}

.container_desc_content_txt img {
    object-fit: contain;
    margin: 0 auto;
}

.gradient {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
}

.view_more_btn {
    margin-top: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background-color: var(--bg-white);
    color: var(--text-hover);
    flex-direction: row-reverse;
    padding: 4px 12px 4px 0px;
    text-align: start;
}

.policy {
    width: 100%;
    background-color: var(--red-cl);
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--bg-white);
    text-align: center;
    margin: 12px 0px;
}

.guide_container_head {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    cursor: pointer;
}

.guide_list,
.policy_list {
    height: 0px;
    transition: var(--trans);
    overflow: hidden;
}

.guide_list_show {
    height: 80px;
    transition: var(--trans);
}

.policy_list_show {
    height: 134px;
    transition: var(--trans);
}

.guide_list li {
    font-weight: 500;
    line-height: 24px;
    list-style-position: inside;
    list-style-type: disc;
    padding: 0 8px;
    font-size: 14px;
}

.icon_right {
    transform: rotate(-90deg);
    transition: var(--trans);
}

.icon_down {
    transform: rotate(0deg);
    transition: var(--trans);
}

.policy_list_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-top: 8px;
}

.policy_list_content {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 8px 0;
}

.recommend_section {
    margin-bottom: 18px;
}

.recommend {
    margin-top: 24px;
    padding: 24px;
    background-color: var(--bg-white);
}

.list_recommend_cnt {
    margin-top: 12px;
}

.list_recommend {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
}

.comment_cnt {
    margin-top: 24px;
    padding: 24px;
    background-color: var(--bg-white);
}

.combo_services {
    margin-top: 12px;
}

.combo_services_list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
}

.combo_services_item {
    position: relative;
}

.combo_item_quantity {
    position: absolute;
    z-index: 2;
    top: 0;
    background-color: var(--bg-white);
    width: 28px;
    height: 28px;
    margin: 4px 0px 0px 4px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    border-radius: 100%;
}

.price_warning {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: var(--red-cl);
    margin-top: 6px;
}

.black {
    color: var(--text-black);
}

@media only screen and (max-width: 1023px) {
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        position: fixed;
        z-index: 102;
        top: 0;
        left: 0;
        width: 100%;
    }

    .head button {
        width: 36px;
        height: 36px;
        padding: 0px;
        border-radius: 100%;
        background-color: #fafafa8b;
    }

    .head_right {
        position: relative;
    }

    .cart_badge {
        position: absolute;
        background-color: var(--red-cl);
        border-radius: 100%;
        color: var(--bgWhite);
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        top: -7px;
        right: -10px;
        min-width: 24px;
        padding: 3px 4px;
        position: absolute;
        text-align: center;
    }

    .wrapper {
        background-color: #FAFAFA;
    }

    .container {
        padding: 0px 0px 60px 0px;
        padding: 0px;
        margin: 0px;
    }

    .container_head {
        flex-direction: column;
    }

    .container_head_thumb {
        margin-top: 0px;
    }

    .container_head_left {
        width: 100%;
    }

    .container_head_img_slide,
    .media_item_video {
        width: 100%;
        height: unset;
        border-radius: 0px;
        margin-bottom: 0px;
    }

    .media_item_video,
    .media_item_img {
        width: 100%;
        height: unset;
        padding-bottom: 100%;
    }

    .container_head_right {
        width: 100%;
        padding: 12px;
        background-color: #FAFAFA;
    }

    .detail_name {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    .duration {
        margin-top: 4px;
    }


    .duration_item_text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    .detail_buy {
        flex-direction: column;
    }

    .detail_price_left {
        margin: 8px 0px;
    }

    .price_percent {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }

    .price span:first-child {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    .price span:nth-child(2) {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    .detail_rate {
        margin: 0px;
    }

    .detail_rate_item {
        padding: 0px;
        margin-right: 8px;
    }

    .detail_sticky_cnt {
        display: none;
    }

    .desc_body_cnt {
        margin-top: 0px;
        width: 100%;
        padding: 0px;
        min-height: unset;
    }

    .container_desc_content_txt p {
        margin-top: 0px;
    }

    .container_desc_content_txt img {
        object-fit: cover;
        width: 100% !important;
        height: unset !important;
        margin: -10px 0px;
    }

    .bottom_wrap{
        display: flex;
        width: 100%;
        gap: 12px;
    }

    .bottom {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 12px 12px 20px 12px;
        z-index: 10;
        background-color: var(--bg-white);
        box-shadow: var(--box-shadow);
        border-radius: 12px 12px 0px 0px;
        display: flex;
        justify-content: space-between;
    }

    .bottom button {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 12px 0px;
        flex-shrink: 0;
    }

    .bottom_wrapper {
        padding: 12px 12px 20px 12px;
        background-color: var(--bg-white);
    }
    .bottom_wrap_btns:nth-child(1){
        width: 40px;
    }
    .bottom_wrap_btns:nth-child(1) button{
        border-radius: 6px;
    }
    .bottom_wrap_btns:nth-child(2){
        width: calc(100% - 66px);
        display: flex;
    }
    .bottom_wrap_btns {
        display: flex;
        gap: 12px;
    }
    .bottom_wrap_btns:nth-child(2) button{
        width: 50%;
    }
    .bottom_wrap_btns:nth-child(2) button:nth-child(2){
        background-color: var(--pr-green);
    }
    .bottom_detail {
        display: flex;
        justify-content: space-between;
    }

    .bottom_detail_img {
        width: 112px;
        height: 112px;
        border-radius: 12px;
    }

    .bottom_detail_info {
        width: calc(100% - 124px);
    }

    .detail_cart {
        flex-direction: column;
    }

    .detail_quantity {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 0px;
        border-top: solid 1px var(--bg-color);
    }

    .detail_quantity_calc {
        margin: 0px;
    }

    .add_cart {
        width: 100%;
        margin-top: 12px;
    }

    .add_cart_btn {
        width: 100%;
        margin: 0px;
        display: none;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 12px 0px;
    }

    .container_desc {
        padding: 12px;
        margin: 0px;
    }

    .container_desc_content {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    .policy {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: start;
    }

    .recommend {
        margin-top: 10px;
        padding: 12px;
    }

    .comment_cnt {
        margin-top: 10px;
        padding: 12px 12px 70px 12px;
    }

    .guide_list_show {
        height: 128px;
    }

    .policy_list_show {
        height: 220px;
    }

    .list_recommend_cnt {
        width: 100%;
        overflow-x: scroll;
    }

    .list_recommend {
        display: flex;
        width: max-content;
        margin-bottom: 6px;
    }

    .list_recommend li {
        width: 164px;
    }

    .org_card_mb {
        background-color: var(--bg-white);
        margin-top: 10px;
    }

    .detail_org {
        border: none;
        padding: 12px;
    }

    .org_avatar {
        width: 62px;
        height: 62px;
    }

    .org_detail {
        width: calc(100% - 62px);
    }

    .org_address {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    .org_distance span {
        font-size: 12px;
        font-weight: 500;
    }

    .combo_services {
        width: 100%;
        overflow-x: scroll;
    }

    .combo_services_list {
        width: 100%;
        display: flex;
        padding-bottom: 8px;
    }

    .combo_services_item {
        width: 164px;
    }

    .detail_dis {
        text-align: center;
        width: 100%;
    }

    .right_btn_share {
        display: block;
        margin-left: 8px;
    }

    .share_cnt_mb {
        padding: 24px 12px;
    }
}
@media screen and (max-width: 767.98px) {
    .bottom_btn .container{
        position: unset !important;
    }
}
