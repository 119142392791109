.container {
  min-height: 100dvh;
}

.body {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
}

.left {
  width: calc(40% - 16px);
  display: flex;
  justify-content: center;
  height: 100%;
}
.left img{
  width: 100%;
  object-fit: cover;
}

.right {
  width: calc(60% - 16px);
  padding: 24px 32px;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
  height: fit-content;
}

.tab_cnt {
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
}

.tab_item {
  padding: 8px 12px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: solid 1px var(--white);
  transition: var(--trans);
}
.tab_item_act{
  color: var(--purple);
  border-bottom: solid 1px var(--purple);
  transition: var(--trans); 
}

@media only screen and (max-width: 1023px) {
  .body{
    padding-top: 64px;
  }
  .left{
    display: none;
  }
  .right{
    width: 100%;
    padding: 0px 12px;
    box-shadow: none;
  }
}