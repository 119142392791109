.beautyx {
    display: flex;
    gap: 92px;
    max-width: 1238px;
    margin: 0 auto;
    padding: 80px 15px;
}
.left {
    width: 394px;
    flex-shrink: 0;
}
.left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.right {
    width: 100%;
}
.title {
    color: #000;
    text-align: center;
    font-family: Quicksand;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 54px */
    margin-bottom: 57px;
}
.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
    gap: 57px 20px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 25px;
}
.item h3 {
    color: #000;
    text-align: center;
    font-family: Quicksand;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
}
.item p {
    color: #000;
    text-align: center;
    font-family: Quicksand;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
}
