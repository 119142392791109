.container {
  background-color: var(--white);
  padding: 12px;
  border-radius: 8px;
}

.section {
  margin-bottom: 16px;
}

.section_title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px var(--grey_2);
}
.section_cmt{
  width: 85%;
}

.description p {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.description {
  margin-top: 12px;
}

.description img {
  margin: 6px auto;
  border-radius: 6px;
  object-fit: cover;
}
.description h2,
.description h3,
.description h1 {
  margin: 16px 0px 8px 0px;
  font-size: 18px;
}

.map_cnt {
  margin-top: 12px;
  width: 100%;
  aspect-ratio: 2 / 0.9;
  background-color: var(--bg-color);
}

.marker_item {
  background-color: #7266ba3f;
  padding: 16px;
  border-radius: 100%;
  position: relative;
}

.marker_item img {
  width: 20px;
  height: 20px;
}

.marker_card {
  position: absolute;
  z-index: 2;
  width: max-content;
  max-width: 230px;
  background-color: var(--white);
  top: calc(-100% - 16px);
  transform: translateX(-47%);
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
}

.marker_card_name {
  font-size: 14px;
  font-weight: 600;
}

.marker_card_address {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--text-black);
  display: -webkit-box;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.more_map {
  color: var(--text-black);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
}

/* trends */
.about_trends_lists {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  margin-top: 14px;
}
.about_trends_item,
.about_trends_video {
  width: 100%;
  height: 100%;
  display: flex;
}
.about_trends_video {
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
  -moz-filter: brightness(80%);
  transition: all 0.3s ease;
}
.about_trends_item {
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
}
.about_trends_item:hover > .about_trends_video {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
  -moz-filter: brightness(100%);
  transition: all 0.3s ease;
}
.about_trends_org {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 2;
  transition: all 0.3s ease;
  cursor: pointer;
}
.about_trends_org_img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
}
.about_trends_ser_name {
  color: var(--white);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 14px;
  font-weight: bold;
}
.about_popup {
  background-color: #000;
  position: relative;
  height: 100vh;
}
.about_popup_btn_cancel {
  z-index: 3;
  background-color: #fff;
  position: absolute;
  border-radius: 100px;
  top: 10px;
  left: 10px;
  transform: rotate(180deg);
  padding: 8px;
}
.about_popup_btn_cancel:hover {
  background-color: var(--bg-color);
}
.about_btn_arr1,
.about_btn_arr2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 10px;
  overflow: hidden;
  border-radius: 100rem;
  background-color: var(--white);
}
.about_btn_arr1 {
  left: 25%;
  transform: translateY(-50%) rotate(-180deg);
}
.about_btn_arr2 {
  right: 25%;
}
.about_popup_videos {
  height: 100dvh;
  position: relative;
}
.about_popup_video {
  height: 100%;
  width: max-content;
  position: relative;
  margin: 0 auto;
}
.about_popup_video > video {
  height: 100%;
}
.trend_item_center {
  position: absolute;
  z-index: 10;
  width: 40px;
  height: 40px;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.about_trends_ser_price {
  background-color: var(--orange);
  color: var(--white);
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  overflow: hidden;
  font-weight: bold;
}
.about_trends_ser_prices {
  margin-top: 2px;
}
.about_popup_video:hover > .about_trends_org {
  transform: translateY(-50px);
  transition: all 0.3s ease;
}
.about_popup .about_trends_org {
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.about_popup .about_trends_org_img {
  width: 50px;
  height: 50px;
}
.about_popup_videos_btns{
  position: absolute;
  right: 14px;
  bottom: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  z-index: 4;
}
.about_popup_videos_btn button{
  background-color: transparent;
  padding: 0;
}
.about_popup_videos_count{
  color: var(--white);
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  margin-top: 4px;
}
.cusDrawer > div:nth-child(3){
  max-width: calc((100% - 30%) / 2);
}
/* close trends */

@media only screen and (max-width: 1023px) {
  .section_cmt{
    width: 100%;
  }
  .cusDrawer > div:nth-child(3){
    max-width: 100%;
    max-height: 80%;
  }
  .map_cnt {
    aspect-ratio: 1 / 1;
  }
  .dialog_video div:nth-child(3) > div{
    overflow-y: unset !important;
  }
  .about_pop_video {
    width: 100%;
    height: 100vh;
  }
  .about_trends_lists {
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }
  .about_trends_item {
    width: 160px;
    flex-shrink: 0;
    border-radius: 8px;
  }
  .about_trends_org_img {
    width: 36px;
    height: 36px;
  }
  .about_trends_ser_name {
    font-size: 12px;
  }
  .about_popup_videos {
    height: 100vh;
  }
  .about_popup_video {
    width: 100%;
    height: 100vh;
  }
  .about_btn_arr1,
  .about_btn_arr2 {
    display: none;
  }
  .about_trends_org {
    right: 4px;
    left: 4px;
    bottom: 8px;
  }

  .about_popup .about_trends_org {
    transform: translateY(-50px);
  }
}
