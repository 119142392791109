.container {
    padding: 12px 0px;
}

.cate_header {
    display: flex;
    align-items: center;
    background-color: #fff0e8;
    width: fit-content;
    padding: 2px 6px;
    border-radius: 4px;
}

.cate_header img {
    width: 18px;
    height: 18px;
}

.cate_header span {
    color: var(--text-orange);
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    cursor: pointer;
    margin-left: 6px;
}

.btn_cnt {
    position: relative;
    border: none;
    outline: none;
    text-align: start;
    margin-right: 12px;
    border-radius: 4px;
}

.cate_par_cnt {
    min-width: 100%;
    width: max-content;
    background-color: var(--bgWhite);
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    box-shadow: var(--box-shadow);
    top: 46px;
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
}

.cate_par_cnt_show {
    transition: all .3;
    opacity: 1;
    visibility: visible;
    top: 36px;
}

.cate_par_list {
    position: relative;
}

.cate_par_list::before {
    content: "";
    top: -8px;
    right: -200px;
    width: 200px;
    height: calc(100% + 16px);
    position: absolute;
    background-color: transparent;
}


.cate_par_list_item {
    cursor: pointer;
    padding: 8px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    transition: all .3s;
    border-radius: 4px;
    text-transform: capitalize
}

.item_child_cnt {
    position: absolute;
    width: 60vw;
    height: max-content;
    background-color: var(--bg-white);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    z-index: 1000;
    left: calc(100% + 12px);
    top: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
}

.item_child_cnt_ser {
    width: 72vw;
    display: flex;
    justify-content: space-between;
}

.item_child_cnt_left {
    width: 25%;
    padding: 16px 12px 0px 6px;
}

.item_child_cnt_title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}

.special_list {
    margin-top: 8px;
}

.special_list_item {
    width: 100%;
    height: fit-content;
    margin-bottom: 8px;
}


.item_child_cnt_right {
    width: 75%;
}

.cate_par_list_item:hover {
    background-color: var(--bg-color);
    transition: all .3s;
}

.cate_par_list:hover .item_child_cnt {
    top: -8px;
    visibility: visible;
    transition: all .3s;
    opacity: 1;
}

.child_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    padding: 12px;
}

.child_list_name {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.child_list_name img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    margin-right: 6px;
}

.child_child_list {
    padding: 4px 12px;
}

.child_child_item {
    margin-bottom: 2px;
    padding: 2.5px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    transition: all .3s;
    text-transform: capitalize;
}

.child_child_item:hover {
    background-color: var(--bg-color);
    transition: all .3s;
}

.tag_item_services {
    background-color: transparent;
    color: var(--text-orange);
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    border: none;
    outline: none;
    margin-right: 12px;
    text-transform: capitalize;
    position: relative;
    text-align: start;
}

.tag_service_child_cnt {
    position: absolute;
    width: 55vw;
    min-height: 300px;
    height: fit-content;
    background-color: var(--bg-white);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    z-index: 1000;
    top: 46px;
    opacity: 0;
    visibility: hidden;
    transition: var(--trans);
    padding: 8px;
}
.tag_service_child_show{
    top: 36px;
    opacity: 1;
    visibility: visible;
    transition: var(--trans);
    height: fit-content;
}
.tag_service_child_list{
    color: var(--text-black);
}

@media only screen and (max-width: 1023px) {
    .container {
        display: none;
    }
}