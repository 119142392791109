.rate_star_list {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.star_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 20%;
}

.star_icon {
  transform: scale(0.9);
}

.star_item_label {
  font-weight: 500;
  margin-top: 12px;
  font-size: 12px;
}