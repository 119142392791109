.container {
    width: 100%;
    background-color: var(--bg-white);
}
.head_top_left_img{
    height: 42px;
    margin-right: 12px;
}

.head_wrapper {
    width: 100%;
    padding: 16px 0px 18px 0px;
}

.head_top,
.head_top_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head_top {
    margin-bottom: 8px;
}

.head_top_right_auth {
    display: flex;
    align-items: center;
}

.head_top_left {
    width: calc(55% - 12px);
    display: flex;
}
.head_top_left_icon{
    position: relative;
}
.head_top_left_home{
    position: relative;
}
.head_top_left_img_hat{
    position: absolute;
    z-index: 2;
    width: 36px;
    top: -18px;
    left: -10px;
    transform: rotate(-16deg);
}
.head_top_left_seller{
    margin-top: 6px;
    position: absolute;
    background-color: var(--pr-green);
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 600;
    color: var(--bgWhite);
}

.head_top_left_search {
    display: flex;
    align-items: center;
    text-align: start;
    position: relative;
    width: calc(100% - 154px);
    max-width: 685px;
    height: 36px;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 110;
}

.head_search_icon {
    position: absolute;
    z-index: 2;
    width: 20px;
    height: 20px;
    right: 17px;
}

.head_search_input {
    height: 100%;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid hsla(0, 0%, 80%, .361);
    border-radius: 17px;
    box-shadow: inset 0 2px 6px rgb(113 97 186 / 20%);
    padding: 0px 32px 0px 12px;
}

.head_top_right {
    /* width: calc(30% - 50px); */
    width: 45%;
    height: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.head_btn_partner {
    margin-right: 12px !important;
    background-color: transparent !important;
    border: solid 1px var(--green) !important;
    color: var(--purple) !important;
}

.head_sign_btn {
    padding: 6px 12px;
    border-radius: 17px;
    border: solid 1px var(--purple);
}

.head_sign_btn:first-child {
    background-color: var(--bg-white);
    border: solid 1px var(--purple);
    color: var(--purple);
    margin-right: 12px;
}

.head_top_right_btn {
    border: 1.2px solid var(--bg-color);
    padding: 8px;
    border-radius: 6px;
    position: relative;
    margin-left: 10px;
    width: 36px;
    height: 36px;
    background-color: var(--bg-white);
    min-width: unset;
    cursor: pointer;
    transition: var(--trans);
    z-index: 1000;
    text-align: start;
}
.bth_language{
    border: 1.2px solid var(--bg-color);
    border-radius: 6px;
    padding: 8px;
    position: relative;
    margin-left: 10px;
    width: 36px;
    height: 36px;
    background-color: var(--bg-white);
    cursor: pointer;
    transition: var(--trans);
    z-index: 1000;
}

.head_top_right_badge {
    position: absolute;
    z-index: 2;
    background-color: var(--red-cl);
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    color: var(--bg-white);
    height: 22px;
    width: 22px;
    text-align: center;
    border-radius: 100%;
    top: -8px;
    right: -10px;
    border: solid 1px var(--bg-white);
}

.head_top_right_user {
    display: flex;
    align-items: center;
}

.head_user_avatar {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    margin-right: 10px;
}

.head_user_name {
    max-width: 120px;
    width: fit-content;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--purple);
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    display: -webkit-box;
}


/* head_noti */

.head_required_sign {
    margin-top: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--purple);
    text-align: center;
}

.noti_list {
    margin-top: 12px;
}

.noti_list li {
    margin-bottom: 6px;
}

.noti_list_link {
    display: flex;
    padding: 6px;
    border-radius: 6px;
    transition: var(--trans);
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: var(--text-black);
}

.noti_list_link:hover {
    background-color: var(--bg-color);
}

.noti_list_link img {
    margin-right: 6px;
}


/* head_menu */

.head_menu,
.head_noti,
.head_langage {
    position: absolute;
    width: 380px;
    height: max-content;
    box-shadow: var(--box-shadow);
    margin-left: -356px;
    border-radius: 8px;
    top: 52px;
    visibility: hidden;
    opacity: 0;
    transition: var(--trans);
    padding: 18px;
    right: 0;
}
.menu_lang_item{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
.menu_lang{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.menu_lang_item p {
    font-weight: bold;
}
.menu_lang_item img{
    width: 24px;
    height: 24px;
}
.head_langage {
    width: max-content;
}
.head_langage.head_menu_show{
    right: 0;
}
.head_menu_show {
    top: 42px;
    visibility: visible;
    opacity: 1;
    transition: var(--trans);
    background-color: var(--bg-white);
}

.head_menu_title {
    width: 100%;
    padding-bottom: 8px;
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu_list {
    margin-top: 12px;
}

.menu_list_item {
    margin-bottom: 6px;
    width: 100%;
}

.menu_item,
.menu_list_item:last-child {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px;
    border-radius: 4px;
    transition: var(--trans);
}

.menu_item:hover {
    background-color: var(--bg-color);
    transition: var(--trans);
}

.menu_item img,
.menu_list_item_left img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.menu_item_text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}

.menu_list_item:last-child {
    justify-content: space-between;
}

.menu_list_item_left {
    display: flex;
    align-items: center;
}

.switch_lang {
    height: 24px;
    width: 60px;
    border: solid 1px var(--purple);
    border-radius: 12px;
    display: flex;
    overflow: hidden;
}

.switch_lang_item {
    width: 50%;
    cursor: pointer;
    transition: var(--trans);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--purple);
    text-align: center;
}

.menu_bottom {
    display: flex;
    justify-content: flex-end;
}

.menu_bottom_btn {
    padding: 7px 18px;
    background-color: var(--purple);
    cursor: pointer;
    color: var(--bgWhite);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    border-radius: 18px;
    transition: all .3s;
}

.head_bot {
    display: flex;
    align-items: center;
}

.head_bot_btn {
    color: var(--purple);
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    /* background-color: #ebe8fd; */
    background-color: transparent;
    padding: 3px 12px;
    border-radius: 4px;
    border: solid 1px var(--green);
}

.head_bot_link {
    margin-left: 12px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    cursor: pointer;
}


/* head_search */

.head_search {
    position: absolute;
    z-index: 101;
    width: 50vw;
    background-color: var(--bg-white);
    margin-top: 22px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    opacity: 0;
    transition: var(--trans);
    top: 20px;
    visibility: hidden;
}

.head_search_show {
    /* margin-top: 12px; */
    top: 30px;
    opacity: 1;
    transition: var(--trans);
    visibility: visible;
}

.head_back_btn {
    display: none !important;
}

@media only screen and (max-width: 1023px) {
    .container{
        background-color: transparent;
    }
    .head_wrapper {
        padding: 8px 12px;
    }

    .head_top {
        margin: 0px;
    }

    .head_top_left_img,
    .head_bot,
    .head_top_right_user,
    .head_top_right_auth {
        display: none;
    }

    /* .head_search{
        display: none;
    } */

    .head_search_input {
        background-color: var(--bg-color);
        border: solid 1px var(--bg-color);
        border-radius: 8px;
        font-weight: 600;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 12px 0px 30px;
        height: 35px;
        padding-left: 40px;
        box-shadow: none;
    }

    .head_top_right {
        width: fit-content;
    }

    .head_top_left {
        /* width: calc(100% - 138px); */
        width: 100%;
    }

    .head_top_left_search {
        width: 100%;
        background-color: var(--bg-white);
        border-radius: 8px;
    }

    .head_btn_partner {
        display: none !important;
    }

    .head_top_right_btn {
        background-color: var(--bg-color);
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .head_top_right_btn img {
        width: 22px;
        height: 22px;
    }

    .head_search {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0px;
        margin-top: 0;
        z-index: 1000;
    }

    .head_search_icon {
        left: 6px;
    }

    .head_noti {
        width: 100vw;
        position: fixed;
        left: 0;
        top: 60px;
        margin-left: 0px;
    }

    .head_search_icon {
        left: 12px;
        right: unset;
    }

    .head_back_btn {
        display: block !important;
        background-color: var(--bg-white) !important;
        padding: 0px !important;
        height: 35px !important;
        width: 44px !important;
    }
    .head_back_btn_change{
        background-color: transparent !important;
    }

    /* change style */
    .container_ch {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        z-index: 101;
    }

    .container_ch_white {
        backdrop-filter: blur(10px);
        box-shadow: var(--box-shadow);
    }

    .head_top_right_btn_ch {
        background-color: transparent;
        border: none;
    }

    /* recommend keyword */
    .re_container {
        position: absolute;
        z-index: 10;
        background-color: var(--bg-white);
        height: 35px;
        width: 100%;
        background-color: var(--bg-color);
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 0px 12px;
    }

    .re_container_text {
        width: calc(100% - 44px) !important;
        color: var(--text-black);
        font-size: 13px;
        font-weight: 600;
    }

    .slider_wrapper {
        position: absolute;
        width: calc(100% - 70px);
        height: 100%;
        background-color: transparent;
        z-index: 10;
    }

    .re_container_btn {
        position: absolute;
        right: 0;
        margin-right: 3px;
        height: 35px;
        padding: 4px 8px;
        padding-left: 16px;
        border-radius: 8px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .re_container_btn img {
        width: 22px;
        height: 22px;
    }
}