.container {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: var(--trans);
    background-color: var(--bgWhite);
}

.container_change {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: var(--trans);
}

.container:hover,
.container_change:hover {
    transform: translateY(-6px);
    transition: var(--trans);
}

.img_container {
    padding-bottom: 100%;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 8px 8px 0px 0px;
}

.img_container_change {
    height: 100%;
    width: 100px;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 8px;
}

.item_percent {
    position: absolute;
    z-index: 3;
    background-color: var(--red-cl);
    border-radius: 4px;
    -webkit-clip-path: polygon(100% 0, 100% 61%, 90% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 61%, 90% 100%, 0 100%, 0 0);
    color: var(--bgWhite);
    font-size: 14px;
    font-weight: 700;
    margin: -8px 0 0 -6px;
    padding: 4px 6px;
    text-align: center;
}

.item_percent::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #ff9e90;
    margin: 12px 0 0 -2px;
    z-index: 5;
}

.item_img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    border-radius: 8px 8px 0px 0px;
}

.item_org_avatar {
    position: absolute;
    z-index: 3;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    right: 6px;
    bottom: 6px;
    box-shadow: var(--box-shadow);
}

.detail_container {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    padding: 8px;
}

.detail_container_change {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    padding: 8px;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item_name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--text-black);
    display: -webkit-box;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.item_name_ch {
    -webkit-line-clamp: 1;
    font-size: 13px;
    line-height: 14px;
}

.item_price {
    display: flex;
    flex-direction: column;
}

.item_price_ch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item_price span:first-child,
.item_price_ch span:first-child {
    color: var(--red-cl);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}

.item_price_ch span:first-child {
    margin-right: 4px;
}

.item_price span:nth-child(2),
.item_price_ch span:nth-child(2) {
    color: var(--text-hover);
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-decoration: line-through;
}

.item_distance {
    display: flex;
    align-items: center;
}

.item_distance span {
    width: 6px;
    height: 6px;
    background-color: var(--green);
    margin-right: 4px;
    border-radius: 100%;
}

.item_address {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
}

.item_address img {
    height: 14px;
    margin: 2px 4px 0 0;
    width: 14px;
}

.item_address span {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: grey;
    display: -webkit-box;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
