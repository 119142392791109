.container {
  margin: 24px 0px;
  padding: 10px;
  background-color: var(--bg-white);
}

.deal_cnt {
  margin-top: 24px;
  padding: 0px 6px;
}

.deal {
  width: 100%;
  aspect-ratio: 2 / 1;
}

.image_cnt {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  position: relative;
  border-radius: 10px;
}

.image {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.slide_btn {
  width: 32px;
  height: 32px;
  padding: 0px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
}
.slide_btn_prev {
  left: -20px;
}
.slide_btn_next {
  right: -20px;
}
@media only screen and (max-width: 992px) and (min-width: 767px) {}

@media only screen and (max-width: 767px) {}