:root {
    --purple: #65558F;
    /* --purple:#E01202; */
    --green: #7fc128;
    --momo-cl: #d4257b;
    --black: #3b3a40;
    --grey: #eeedf2;
    --grey_2: #fafafa;
    --hover: #000000;
    --bgGray: #e5e5e5;
    /* --bgWhite: #f5f5f7; */
    --bgWhite: #ffffff;
    --darkBlue: #4e4693;
    --white: #ffffff;
    --red_2: #e64d4a;
    --orange: #ff721f;
    --orange-cl: #ff5f00;
    --pr-green: #abd372;
    --gray-header-cl: rgba(203, 203, 203, 0.2);
    --black-cl: #000;
    --white-cl: #fff;
    --gray-cl: #d9d9d9;
    --gray-text-2: #b3b3b3;
    --text-gray: #757575;
    --black-btn: #2c2c2c;
    --title-black-text: #1e1e1e;
    --bg-gray: #f5f5f5;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
body {
    position: relative;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.flexX-gap-12 {
    display: flex;
    gap: 0 12px;
    grid-gap: 0 12px;
    align-items: center;
}
.flexX-gap-16 {
    display: flex;
    gap: 0 16px;
    grid-gap: 0 16px;
    align-items: center;
}

.flexX-gap-8 {
    display: flex;
    gap: 0 8px;
    grid-gap: 0 8px;
    align-items: center;
}

.flexX-gap-4 {
    display: flex;
    gap: 0 4px;
    grid-gap: 0 4px;
    align-items: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* responsive */
.home-mb {
    display: none;
}

/* Mobile */
@media only screen and (max-width: 739px) {
    .home-mb {
        display: none;
        padding-top: 600px;
        width: 100vh;
        height: 1000vh;
        z-index: 1000;
        background-color: var(--bg-gray);
    }

    .home-mb__title {
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        color: var(--purple);
    }
}

.form-contact {
    padding: 36px;
    background-color: var(--bgWhite);
    text-align: center;
    border-radius: 16px;
    overflow: hidden;
}

.form-contact .form-contact__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: var(--purple);
    margin-bottom: 8px;
}

.form-contact .form-contact__desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.form-contact .wrap-btn:not(:nth-child(2)) {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.form-contact .err-text {
    text-align: left;
    margin: 8px 0 0 10px;
}

.form-contact .sign-form__box {
    margin-top: 16px;
}
