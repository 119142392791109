.alert_cnt {
  width: 450px;
  background-color: var(--white);
  padding: 24px;
}

.alert_title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.alert_message {
  margin: 10px 0px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.alert_action{
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .alert_cnt{
    width: calc(100vw - 24px);
  }
}