.container {
    padding: 12px;
    border: solid 1px var(--purple);
    border-radius: 8px;
}

.head,
.head_text {
    display: flex;
    align-items: center;
}

.head_text {
    margin-left: 8px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
}

.head_text span:first-child {
    color: #ABD373;
}

.body {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 6px 0px;
}

.link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
}