.container {
    background-color: var(--bg-white);
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 95vh;
    max-width: 520px;
    padding: 12px;
    position: relative;
    width: 70vw;
    overflow-y: hidden;
}

.body {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.status {
    padding: 18px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    background-color: rgb(171, 211, 115);
}

.status img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.status_content {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: var(--bg-white);
    display: flex;
    flex-direction: column;
}

.info {
    margin-top: 12px;
    background-color: #FAFAFA;
    padding: 4px 12px;
    border-radius: 6px;
}

.info_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.info_row {
    margin-top: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
}

.info_row_value {
    margin-left: 4px;
    font-weight: 600;
}

.org {
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.org_img {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    background-color: var(--bg-color);
    position: relative;
    margin-right: 8px;
}

.org_img img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
    object-fit: cover;
}

.org_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    transition: var(--trans);
    display: flex;
    align-items: center;
}

.list_service {
    margin-top: 8px;
}

.list_service_item {
    padding: 7px 0px;
    position: relative;
}

.service {
    display: flex;
    justify-content: space-between;
}

.service_img {
    width: 64px;
    height: 64px;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 6px;
}

.service_img img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}

.service_detail {
    width: calc(100% - 70px);
}

.service_name,
.service_duration {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.service_name {
    font-weight: 700;
    margin-bottom: 4px;
}

.note {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 6px;
    border: solid 1px var(--bg-color);
}

@media screen and (max-width: 1023.98px) {
    .container {
        margin-top: 44px;
        padding: 0px;
        width: 100%;
        max-height: unset;
    }

    .status {
        border-radius: 0px;
    }

    .info {
        margin-top: 0px;
    }

    .org,
    .list_service {
        padding: 0px 12px;
    }

    .note {
        border-radius: 0px;
    }

    .scan_btn {
        padding: 0px;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        margin-left: 6px;
        background-color: transparent;
    }
}