.container {
    display: flex;
    align-items: center;
}

.ring_container {
    position: relative;
    width: 13px;
    height: 13px;
    margin-right: 4px;
}

.circle {
    width: 13px;
    height: 13px;
    background-color: #62bd19;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.ringring {
    border: 2px solid #62bd19;
    border-radius: 30px;
    height: 21px;
    width: 21px;
    position: absolute;
    left: -4px;
    top: -4px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
}
.text{
    display: flex;
    align-items: center;
    margin: 0px 4px;
    font-size: 14px;
    font-weight: 500;
}
.value{
    margin-left: 3px;
}

@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.1, 1.1);
        opacity: 0;
    }
}