.bt {
  display: none;
}
/* res-mobile */
/* Mobile */
@media only screen and (max-width: 1023px) {
  .bt {
    display: block;
    padding: 12px 8px 20px 8px;
    background-color: hsla(0,0%,100%,.75);
    backdrop-filter: blur(9px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    box-shadow: 0px -3px 20px rgba(113, 97, 186, 0.15);
    border-radius: 12px 12px 0px 0px;
  }
  .bt-cnt__item {
    width: 25%;
    position: relative;
  }
  .bt-cnt__item-badge {
    margin: -2px 0px 0px 10px;
    width: 8px;
    height: 8px;
    position: absolute;
    font-size: 10px;
    text-align: center;
    font-weight: 100;
    line-height: 14px;
    border-radius: 100%;
    background-color: var(--red-cl);
    color: var(--bg-gray);
  }
  .bt-cnt__item img {
    width: 20px;
    height: 20px;
  }
  .bt-cnt__item:last-child img{
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }
  .bt-cnt__item-title {
    margin-top: 4px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--text-hover);
  }
}
