.container,.list_btn{
    display: flex;
    align-items: center;
}
.title{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
}
.list_btn{
    margin-left: 6px;
}
.share_btn{
    margin-right: 8px;
}
.clip_board_icon{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0px;
    margin-top: -2px;
}