.container {
    background-color: var(--bg-white);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
}

.pop_noti_desc {
    margin: 10px 0px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
}

.popup_noti_title {
    font-size: 20px;
    font-weight: bold;
}

.pop_noti_bot {
    width: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: center;
}

.pop_noti_bot button {
    margin: 0px 8px;
}

/* message */
.wrapper {
    width: 100vw;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
}

.message_container {
    width: 100%;
    max-width: 400px;
    height: 96px;
    backdrop-filter: blur(10px);
    background-color: #ffffff99;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
}

.message_left {
    width: 80px;
    height: 100%;
    background-color: var(--bg-white);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.message_left_icon {
    width: 70px;
    height: 70px;
    border-radius: 12px;
}

.message_right {
    width: calc(100% - 88px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.message_right_content {
    font-size: 14px;
    font-weight: 700;
}

.message_right_bot {
    display: flex;
    justify-content: flex-end;
}
/* PopupBtxReward */

.popup_btx_reward {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: calc(100vw - 48px); */
    max-width: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
    border-radius: 16px;
}
.icon_btx_reward {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
}
.title_btx_reward {
    font-size: 18px;
    color: var(--bg-white);
    font-weight: normal;
    margin: 32px 0 8px 0;
}
.point_btx_reward {
    font-size: 32px;
    font-weight: 700;
    color: #fedd5f;
}
.note_btx_reward {
    font-size: 16px;
    color: var(--bg-white);
}
.desc_btx_reward {
    font-size: 14px;
    padding: 4px 12px;
    background-color: rgba(66, 66, 66, 0.411);
    border-radius: 50px;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.69);
    text-align: center;
}
.btn_btx_reward {
    margin-top: 16px;
    background-color: #fedd5f;
    border-radius: 50px;
    color: #d45423;
}

@media screen and (max-width: 1023.98px) {
    .container {
        padding: 24px 8px;
        border-radius: 6px;
    }

    .pop_noti_bot button {
        margin: 0px 4px;
    }
}
@media screen and (max-width: 767.98px) {
    .popup_btx_reward {
        width: calc(100vw - 48px);
    }
}
