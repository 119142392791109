.container {
    width: 100%;
    height: 100%;
    background-color: var(--bg-white);
}

.container_head {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    padding: 8px 12px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container_head button {
    padding: 2px;
    background-color: transparent;
}

.container_img {
    width: 100%;
    position: relative;
}

.container_img_back {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container_img_box {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 2;
    background-color: #1b1b1b83;
    backdrop-filter: blur(10px);
}

.container_img_box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content_container {
    position: fixed;
    bottom: 82px;
    left: 0;
    width: 100%;
    z-index: 10;
}