.home_fettier{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  pointer-events: none;
}
.home_fettier_left, .home_fettier_right{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100vh;
  width: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_fettier_left{
  left: 0;
}
.home_fettier_right{
  right: 0;
}