.container {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    display: flex;
    justify-content: space-between;
    border: solid 1px var(--bg-white);
    transition: var(--trans);
}
.act{
    background-color: rgb(255, 227, 210);
    border: solid 1px var(--text-orange);
    transition: var(--trans);
}

.left {
    width: 110px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.org_img {
    width: 86px;
    height: 86px;
    margin-right: 8px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
    border: solid 1px var(--bg-color);
}

.org_img img {
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
    position: absolute;
    object-fit: cover;
}

.org_name {
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.right {
    width: calc(100% - 110px);
    min-height: 110px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    border-left: solid 1px var(--bg-color);
}

.title {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
}

.minimum_order_label {
    margin-top: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.item_name,
.time_slot_label,
.date_slot_label {
    margin-top: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    display: inline-block;
    color: var(--green);
}

.time_slot_label,
.date_slot_label {
    color: var(--text-black);
}
.bottom{
    margin-top: 2px;
    display: flex;
    justify-content: flex-end;
}
.bottom_btn{
    padding: 3px 10px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    background-color: var(--text-orange);
}