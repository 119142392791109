.cnt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--trans);
}
.left{
  display: flex;
  align-items: center;
}
.left img{
  width: 32px;
  height: 32px;
  margin-right: 6px;
}
.left_value p:nth-child(1){
  font-size: 15px;
  font-weight: 700;
}
.left_value_gray{
  font-size: 13px !important;
  font-weight: 500 !important;
  color: var(--text-hover);
}
.right label{
  margin-right: 0px;
}