.container {
    margin-top: 24px;
    padding: 10px;
    background: #fff;
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.body {
    padding: 24px 0px;
    width: 100%;
    overflow-x: scroll;
}

.list_item {
    display: flex;
    width: max-content;
}

.item_cnt {
    cursor: pointer;
    width: 184px;
    margin-right: 12px;
    border-radius: 8px;
    transition: all 0.4s;
}

.item_cnt:hover {
    box-shadow: 0 6px 15px #dcd8ee;
    transform: translateY(-7px);
    transition: all 0.4s;
}

.card_item_common {
    width: 100%;
    height: 100%;
}

.ser_img_cnt {
    position: relative;
}

.ser_img_org_logo {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-radius: 100%;
    width: 35px;
    background-color: var(--bg-white);
    border: 1px solid #7161ba9c;
}

.img_service {
    width: 100%;
    height: 182px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.ser_pro_item_cnt {
    gap: 4px 0;
    padding: 8px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    flex-shrink: 0;
}

.ser_name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: var(--text-black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.ser_price {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.ser_price span:first-child {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: var(--red-cl);
}

.ser_price span:nth-child(2) {
    font-size: 12px;
    line-height: 14px;
    color: var(--text-hover);
    font-weight: 700;
    text-decoration: line-through;
}

.ser_org_address {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ser_org_address img {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin: 2px 4px 0 0;
}

.ser_org_address p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    color: grey;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.ser_promo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ser_promo_percent {
    width: 90px;
    padding: 4px 0px;
    background-color: var(--red-cl);
    font-size: 14px;
    font-weight: 700;
    color: var(--bgWhite);
    text-align: center;
    border-radius: 4px;
    margin: -8px 0px 0px -6px;
    clip-path: polygon(100% 0, 100% 61%, 90% 100%, 0 100%, 0 0);
}

.ser_promo_percent::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #ff9e90;
    margin: 12px 0 0 4px;
}

@media screen and (max-width: 1023px) {
    .container {
        padding: 16px 12px 0 12px;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
    }
    .body {
        padding: 12px 0px;
    }

    .item_cnt {
        width: 164px;
        margin-right: 12px;
    }
}
