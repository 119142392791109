.login_zalo_btn{
  background-color: var(--bg-white);
}
.zaloCnt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zaloName {
  color: #0067FF;
  font-size: 40px;
  font-weight: bold;
}

.zaloContent {
  display: block;
  margin-bottom: 18px;
  color: #333;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  margin: 12px 0px;
}
.zaloQrCnt{
  margin-top: 12px;
  width: 30%;
  aspect-ratio: 1/1;
  background-color: var(--bg-white);
  box-shadow: var(--box-shadow);
  border-radius: 6px;
}
.zaloQrCnt{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
}
.zaloQrTitle{
  font-size: 20px;
  font-weight: bold;
}
.zaloQrGuide{
  font-size: 14px;
  font-weight: 500;
}
.zaloQr{
  width: 90%;
  aspect-ratio: 1/1;
}
.zaloLoadAll{
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}