.wrapper {
  background-color: var(--grey_2);
  border-top: solid 1px var(--grey);
}

.container {
  padding: 32px 0px;
}

.tab_wrapper {
  padding: 12px 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: var(--grey_2);
  position: sticky;
  z-index: 4;
  top: 0px;
}

.tab_cnt {
  background-color: var(--bg-gray-1);
  padding: 4px 8px;
  border-radius: 6px;
  display: flex;
}

.tab_item {
  display: block;
  transition: var(--trans);
  padding: 6px 8px;
  border-bottom: solid 1px transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}

.tab_item_act {
  color: var(--purple);
  border-bottom: solid 1px var(--purple);
  transition: var(--trans);
}

.body_section {
  margin-top: 18px;
  height: max-content;
  background-color: transparent;
}


.cus_title_review{
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}
.cus_title_review .icon_review{
  width: 60px;
  position: absolute;
  right: -115%;
  top: 50%;
  transform: translateY(-42%);

}
.tab_item_review{
    background: linear-gradient(
        to right,
        #7953cd 20%,
        #00affa 30%,
        #0190cd 70%,
        #764ada 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 2s ease-in-out infinite alternate;
}

@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    border: none;
  }

  .container {
    padding: 0px;
  }

  .tab_wrapper {
    padding: 0px;
    background-color: var(--grey_2);
    width: 100%;
    overflow-x: scroll;
    top: 51px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
  }

  .tab_cnt {
    width: max-content;
    background-color: var(--grey_2);
  }

  .tab_item {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
}