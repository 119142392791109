.container {
    padding: 10px;
    background: #fff;
}
.description{
    font-size: 15px;
    font-weight: 400;
}

.trends_list_cnt {
    margin-top: 24px;
}

.trends_list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
}

.trends_list_item {
    width: 100%;
}

.trend_item {
    display: block;
    position: relative;
   cursor: pointer;
    padding-bottom: 178%;
    border-radius: 8px;
}

.trends_list_item_video {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    filter: brightness(80%);
    transition: var(--trans);
}

.trends_list_item_video:hover {
    filter: brightness(100%);
    transition: var(--trans);
}

.trend_item_info {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trend_item_org_img {
    width: 38px;
    height: 38px;
    border-radius: 12px;
}

.trend_item_title {
    width: calc(100% - 44px);
    color: var(--bg-white);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
@media screen and (max-width: 1023.98px) {
    .trends_list_cnt{
        margin-top: 16px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
    .trends_list{
        width: max-content;
    }
    .trends_list_item{
        width: 164px;
    }
}