.customer_wraper{
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 200;
  max-width: 600px;
  pointer-events: none;
}
.customer{
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 4px;
  gap: 4px;
  width: max-content;
  background-color: var(--purple);  
  border-radius: 100px;
  overflow: hidden;
  height: 32px;
}
.customer_lootie{
  max-width: 350px;
  transform: translateY(70%);
  z-index: 50;
}
.customer_name{
  font-size: 10px;
  font-weight: bold;
  color: var(--white);
  display:-webkit-box;
  -webkit-line-clamp:1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@media screen and (max-width: 767px) {
  .customer_wraper{
    bottom: 90px;
    left: 12px;
    width: 100%;
  }
  .customer{
    padding: 4px 8px 4px 4px;
    gap: 6px;
    max-width: calc(100% - 74px);
  }
  .customer_name{
    -webkit-line-clamp: 2;
  }
}
