.container {
    margin: 24px 0px;
    border-radius: 4px;
    /* background-color: var(--bg-color); */
    /* padding: 12px; */
}

.head {
    width: 100%;
    background-color: var(--bg-white);
    margin-bottom: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.tab_btn {
    width: 50%;
    border-radius: 8px 0px 0px 8px;
    background-color: transparent;
    color: var(--text-black);
    border-bottom: solid 1px var(--bg-white);
}

.tab_btn:nth-child(2) {
    border-radius: 0px 8px 8px 0px;
}

.tab_btn_act {
    background-color: #F2F1FF;
    color: var(--purple);
    border-bottom: solid 1px var(--purple);
}

.body {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 230px;
    height: 100vh;
    overflow-y: scroll;
    position: sticky;
    margin-top: 0px;
    top: 12px;
}

.left_cate_par_list {
    padding: 8px;
    background-color: var(--bg-white);
    border-radius: 8px;
}

.left_cate_par_item {
    display: flex;
    align-items: center;
    padding: 6px 4px;
    border-radius: 8px;
    cursor: pointer;
    transition: var(--trans);
}

.cate_par_act {
    background-color: var(--bg-color);
    transition: var(--trans);
}

.left_cate_par_item:hover {
    background-color: var(--bg-color);
    transition: var(--trans);
}

.left_cate_par_img {
    position: relative;
    width: 38px;
    height: 38px;
    background-color: var(--bg-color);
    border-radius: 8px;
}

.left_cate_par_img img {
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.left_cate_par_name {
    margin-left: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.right {
    width: calc(100% - 241px);
    padding: 8px;
    background-color: var(--bg-white);
    border-radius: 8px;
    min-height: 100vh;
    height: max-content;
}

.right_tag_child {
    width: 100%;
    overflow-x: scroll;
}

.right_tag_child_list {
    display: flex;
    align-items: center;
    width: max-content;
}

.right_tag_child_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    width: 112px;
    cursor: pointer;
}

.tag_child_img {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    position: relative;
}

.tag_child_img img {
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tag_child_name {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    min-height: 28px;
    color: var(--text-hover);
    transition: var(--trans);
    opacity: 0.5;
}

.chile_name_act {
    color: var(--text-black);
    font-weight: bold;
    transition: var(--trans);
    opacity: 1;
}

.right_org_cnt {
    padding: 6px 0px;
}

.right_org_cnt_label {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: var(--text-black);
    display: flex;
    justify-content: space-between;
}

.right_org_cnt_label a {
    padding-left: 10px;
}

.right_org_list {
    display: flex;
    flex-wrap: wrap;
}

.right_org_item {
    width: 8.3%;
    padding: 4px;
    cursor: pointer;
}

.right_org_item_img {
    display: block;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 6px;
    padding-bottom: 100%;
}

.right_org_item_img img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}


.service_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 12px;
}

.product_child_child_labe {
    margin: 8px 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.product_child_child_list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 12px;
}

.product_child_child_item {
    cursor: pointer;
}

.child_child_img {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.child_child_img img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 8px;
    object-fit: cover;
}

.child_child_name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-top: 6px;
    text-align: center;
}

@media only screen and (max-width: 1023px) {
    .container {
        padding: 0px;
        margin: 0px;
    }

    .head {
        border: 0px;
        margin: 0px;
        position: fixed;
        width: 100%;
        background-color: var(--bg-white);
        z-index: 10;
    }

    .tab_btn {
        border-radius: 0px !important;
    }

    .left {
        width: 25%;
        height: 100%;
        background-color: var(--grey);
        position: fixed;
        left: 0;
        top: 0px;
        z-index: 9;
        padding: 37px 0px 72px 0px;
        padding-top: 0px;
    }

    .left_cate_par_list {
        padding: 0px;
        background-color: var(--grey);
    }

    .left_cate_par_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px;
        border-bottom: solid 1px var(--bg-white);
        border-radius: 0px;
    }

    .cate_par_act {
        background-color: var(--bg-white) !important;
        border-radius: 8px 0px 0px 8px;
    }

    .left_cate_par_img,
    .tag_child_img {
        width: 44px;
        height: 44px;
        border-radius: 100%;
    }

    .left_cate_par_img img,
    .tag_child_img img {
        border-radius: 100%;
    }

    .left_cate_par_name {
        text-align: center;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        margin-top: 4px;
    }

    .right {
        position: fixed;
        right: 0;
        width: 75%;
        margin-top: 37px;
        height: 100%;
        overflow: scroll;
        min-height: unset;
        padding: 6px 6px 110px 6px;
        margin-top: 0px;
    }

    .right_tag_child_item {
        width: 94px;
    }

    .tag_child_name {
        font-size: 11px;
    }

    .right_org_cnt {
        border-bottom: solid 1px var(--bg-color);
        border-top: solid 1px var(--bg-color);
    }

    .right_org_cnt_label {
        font-size: 14px;
        line-height: 16px;
    }

    .right_org_item {
        width: 25%;
    }

    .service_list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
    }

    .service_list_item {
       padding: 8px 4px;
       border-bottom: solid 1px var(--grey);
    }

    .product_child_child_list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 8px
    }

    .child_child_name {
        font-size: 12px;
        line-height: 14px;
    }

    .product_child_child_labe {
        border-top: solid 1px var(--grey);
        padding-top: 12px;
    }

    /* SERVICE ITEM */
    .service_item_cnt {
        width: 100%;
        display: flex;
        aspect-ratio: 4/1;
        border-radius: 8px;
    }

    .service_item_img {
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 8px;
    }

    .service_item_detail {
        height: 100%;
        aspect-ratio: 3/1;
        padding: 2px 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .service_item_detail_name {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: var(--text-black);
        display: -webkit-box;
        line-height: 16px;
        min-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        font-size: 14px;
        font-weight: 600;
    }

    .service_item_detail_price {
        display: flex;
        align-items: center;
    }

    .service_item_detail_price span {
        font-size: 12px;
        font-weight: 500;
    }

    .item_address {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
    }

    .item_address img {
        height: 14px;
        margin: 2px 4px 0 0;
        width: 14px;
    }

    .item_address span {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: grey;
        display: -webkit-box;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

}