.container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.container_image_mb {
    display: none;
    object-fit: cover;
}

@media only screen and (max-width: 767px) {
    .container_image {
        display: none;
    }

    .container_image_mb {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}