.footer {
    width: 100%;
    background-color: var(--purple);
    padding-bottom: 42px;
}
.footer-cnt {
    display: flex;
    width: 100%;
    align-items: flex-start;
}
.footer-left_bottom{
    margin-top: 24px;
}
.footer-left {
    width: 50%;
    padding: 42px 0px;
}
.footer-cnt .wrap {
    width: 100%;
    padding: 0px 10px;
}
.footer-cnt__item-title {
    font-size: 20px;
    line-height: 28px;
    color: var(--bgWhite);
    font-weight: 700;
}
.footer-cnt__item ul {
    margin-top: 18px;
}
.footer-cnt__item ul li {
    color: var(--bgWhite);
    cursor: pointer;
    margin: 4px 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.footer .address {
    text-align: center;
    margin-top: 32px;
    font-size: 10px;
    line-height: 14px;
    color: var(--bgWhite);
    cursor: pointer;
}
.footer .footer-right {
    width: 50%;
    padding: 42px 0px;
    display: flex;
    flex-direction: row;
}
.footer .social-list {
    display: flex;
    align-items: center;
    margin-top: 18px;
    grid-gap: 0 16px;
}
.footer .social-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    width: 25%;
}
.row-center{
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-left li:hover{
    /* color: #81c12e; */
    text-decoration: underline;
}
.logo-myspa{
    width: 100px;
    /* filter: drop-shadow(0 0 0.01rem black); */
    cursor: pointer;
}
.footer .social-item {
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    width: 36px;
    height: 36px;
}
.footer .social-item__method {
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
}
.footer .social-item__img {
    width: 36px;
    height: 36px;
}
.footer .social-item__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.footer .down-app {
    margin-top: 18px;
    display: flex;
    align-items: center;
    grid-gap: 0 12px;
}
.footer .down-app__qr {
    width: 40%;
    border-radius: 4px;
    background-color: #fff;
}

.footer .down-app__qr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.footer .down-app__wrap {
    width: 60%;
    display: flex;
    flex-direction: column;
    grid-gap: 12px 0;
}
.footer .down-app__btn {
    cursor: pointer;
}
.footer .down-app__btn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.footer-copy-right{
    width: 100%;
    text-align: center;
    margin-top: 6px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--white);
}
/* popup qr code mini app */
.footer-pop-child{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-pop-child-title{
    display: flex;
    align-items: flex-end;
}
.footer-pop-child-title span{
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}
.footer-pop-child-title img{
    width: 36px;
    height: 36px;
    margin-left: 6px;
}
.footer-pop-child-qr-code{
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-top: 16px;
}
.aws_starups{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.aws_startups_img{
    width: 150px;
}
.aws_starups > p {
    margin-bottom: 12px;
    color: #fff;
    font-size: 12px;
}

.footer-left_top{
    display: flex;
}
/* Mobile */
@media only screen and (max-width: 769px) {
    .footer {
        display: none;
    }
    .footer-cnt {
        flex-direction: column;
        padding: 24px 0px;
    }
    .footer-cnt__item-title {
        width: 100%;
        text-align: center;
        margin-top: 12px;
    }
    .footer-cnt .wrap {
        width: 100%;
    }
    .footer-cnt__item ul {
        margin-top: 12px;
    }
    .footer-cnt__item ul li {
        text-align: center;
    }
}
