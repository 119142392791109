.input_cnt {
    height: 100%;
    min-height: 30px;
    position: relative;
}

.input_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    width: 20px;
    height: 20px;
}

.input_cnt img {
    z-index: 2;
}

.input_cus {
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid rgba(204, 204, 204, 0.361);
    outline: none;
    background-color: var(--bg-white);
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: inset 0 2px 6px rgb(113 97 186 / 20%);
}

.input_cnt input::-webkit-input-placeholder {
    font-weight: bold;
    font-size: 14px;
    color: var(--black);
    opacity: 0.5;
    letter-spacing: normal;
}

/* .input_cnt input:focus {
    border: 1px solid var(--purple);
    -webkit-box-shadow: 0 0 0 4px rgba(222, 225, 255, 0.712);
    box-shadow: 0 0 0 4px rgba(227, 222, 255, 0.712);
} */

@media screen and (max-width: 1023.98px) {
    .input_cnt input {
        border: solid 1px var(--bg-color);
    }
}