.wrapper {
    padding: 16px 0px;
}
.container {
    display: flex;
    align-items: center;
}

.item_btn {
    padding: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    margin-right: 24px;
    color: var(--text-orange);
}

.item_title {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .home_cate2 {
        position: relative;
        height: 100%;
    }
    .wrapper {
        padding: 32px 12px 28px 12px;
        overflow-x: scroll;
    }

    .container {
        width: max-content;
    }

    .item_cnt {
        /* width: 84px; */
        width: 84px;
        /* width: max-content; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .item_btn {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 0px;
        border-radius: 100%;
        background-color: #e9e9e9;
        margin: 0px;
    }

    .item_btn img {
        width: 22px;
        height: 22px;
    }

    .item_title {
        display: block;
        font-weight: 700;
        font-size: 11px;
        line-height: 22px;
        margin-top: 4px;
    }
}
