.btn-loading {
    padding: 6px 19px;
    border: 1px solid transparent ;
    outline: none;
    cursor: pointer;
    background-color: var(--purple);
    color: var(--bgWhite);
    border-radius: 18px;
    transition: all 0.3s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
}

.btn-loading:hover {
    opacity: 0.8;
}

.loading-cnt {
    position: absolute;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.509);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-loading img {
    margin-right: 4px;
}

@media only screen and (max-width: 1023px) {
    .btn-loading {
        /* width: 100%; */
        padding: 8px 38px;
        border-radius: 6px;
    }
}
