.container {
    width: 100%;
    background-color: var(--bg-white);
    padding: 18px 0px;
}

.button {
    background-color: var(--bg-white);
    border: solid 1px var(--purple);
    color: var(--purple);
}

.wrapper {
    display: flex;
    align-items: center;
}

.head_icon {
    margin: 0px 12px;
}

.head_title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 9px;
    color: var(--purple);
}

@media only screen and (max-width: 1023px) {
    .container {
        padding: 6px 12px;
        height: 44px;
        box-shadow: var(--box-shadow);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1001;
        background-color: var(--bg-white);
    }

    .wrapper {
        justify-content: space-between;
    }

    .button {
        width: 32px;
        height: 32px;
        padding: 0px;
        border: none;
    }

    .button img {
        width: 24px;
        height: 24px;
    }

    .head_title {
        font-size: 18px;
        line-height: 34px;
        font-weight: 700;
        color: var(--text-black);
        margin: 0px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .head_icon {
        display: none;
    }

    .head_left {
        width: fit-content;
        min-width: 32px;
        max-width: 56px;
        height: 32px;
        display: flex;
        align-items: center;
    }
}