.container{
  background-color: var(--white);
}
.section_item,
.section_item_discount {
  padding: 12px 0px;
}

.section_item_title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 12px;
  color: var(--orange);
  background-color: var(--pink);
  width: max-content;
  border-radius: 6px 6px 0px 0px;
  border-bottom: solid 1px var(--orange);
}

.discount_list,
.special_list {
  grid-gap: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

@media only screen and (max-width: 992px) and (min-width: 767px) {}

@media only screen and (max-width: 767px) {
  .container{
    padding: 0px 12px;
  }
  .section_item,.section_item_discount{
    padding: 8px 0px;
  }
  .section_item_discount{
    width: 100%;
    overflow-x: scroll;
  }
  .discount_list{
    display: flex;
    width: max-content;
  }
  .discount_item{
    width: 156px;
  }
  .section_item_title{
    padding: 6px 14px;
  }
  .special_list {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .special_list_item{
    height: 100px;
  }
}