
.order-list-tab .MuiTabPanel-root {
  padding: 12px 0px !important;
}

.order-list-tab .MuiButtonBase-root {
  color: var(--purple) !important;
  text-transform: none !important;
}

.order-list-tab .MuiTabs-indicator {
  background-color: var(--purple) !important;
}
.order-list__bottom {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

/* load skelton */
.order-load__cnt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order_load_cnt {
  width: 100% !important;
  height: 208px;
  margin-bottom: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order_load_cnt_head {
  width: 100%;
  height: 16px;
}

.order_load_cnt_body {
  width: 100%;
  height: 116px;
}

.order_load_cnt_bot {
  width: 100%;
  height: 24px;
}


/* Mobile */
@media only screen and (max-width: 1023px) {
  .order-head {
    padding-bottom: 6px;
  }

  .order {
    padding: 0px;
    padding-bottom: 72px;
  }

  .order-list-tab .MuiTabs-root {
    min-height: 0px !important;
    border-bottom: solid 1px var(--grey) !important;
  }

  .order-list-tab .MuiButtonBase-root {
    min-height: 0px !important;
    padding: 4px 12px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: "Nunito" !important;
    width: 50% !important;
  }

  .order-list-tab .MuiTabs-indicator {
    width: 24px !important;
    margin-left: 20% !important;
  }

  .order-list__cnt li {
    width: 100%;
    padding: 8px 0px;
  }

  .order-list-tab .MuiTabPanel-root {
    padding: 0px !important;
  }

  /* order item */
  .order-item {
    border: solid 1px var(--grey);
  }

  .order-item__head .left {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .order-item__head .right {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .order-item__date .left span {
    font-size: 14px;
    line-height: 20px;
  }

  .order-item__date .right {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .order-item__body-img {
    width: 72px;
    height: 72px;
  }

  .order-item__body-de {
    width: calc(100% - 72px);
    height: 72px;
  }

  .order-item__body-de .org__address {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .order-item__status .status {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  /* order item end */
  .order-list__bottom button {
    background-color: transparent;
    color: var(--purple);
  }

  .order_load_cnt {
    width: 100% !important;
  }
  

}