.container {
    width: 100%;
    padding: 12px 8px;
    border-top: solid 1px var(--bg-color);
}

.title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.location {
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.location_text {
    width: calc(100% - 32px);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--text-black);
    display: -webkit-box;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.location_btn {
    padding: 6px;
    background-color: transparent;
    border: none;
}

.province_cnt {
    width: 23vw;
    height: 100vh;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.province_list_cnt {
    height: calc(100% - 50px);
    overflow-y: scroll;
}

.province_item {
    padding: 4px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.province_item_name {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.radio_btn {
    width: 20px;
    height: 20px;
    border: solid 2px var(--black);
    border-radius: 100%;
    cursor: pointer;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio_btn_check {
    width: 11px;
    height: 11px;
    background-color: var(--purple);
    border-radius: 100px;
}

.province_name {
    width: calc(100% - 20px);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--text-black);
    display: -webkit-box;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    cursor: pointer;
}

.che_right_btn {
    background-color: transparent;
    border: none;
    padding: 2px;
    width: 64px;
    display: flex;
    justify-content: flex-end;
}

.che_right_btn img {
    margin: 0px;
}

/* price */
.container {
    width: 100%;
    height: fit-content;
    padding: 6px;
    border-bottom: solid 1px var(--bg-color);
}

.filter_title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}

.body_title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: var(--text-hover);
}

.price_cnt {
    padding: 8px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
}

.price_item {
    height: 34px;
}

.price_item input {
    padding: 8px 6px;
}

.price_invalid {
    margin: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: var(--red-cl);
}

.price_btn {
    width: 100%;
    border-radius: 6px;
}

.price_list_cnt {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 6px;
}

.price_list_item {
    width: calc(50% - 6px);
    padding: 6px;
    margin: 4px 0px;
    background-color: var(--bg-color);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    transition: var(--trans);
    font-size: 14px;
    font-weight: 700;
}

/* tags */
.tag_item_cnt {
    padding: 4px;
    cursor: pointer;
    padding-left: 28px;
    position: relative;
}

.tag_item_cnt_img {
    display: none;
}

.tag_check {
    width: 18px;
    height: 18px;
    border: solid 1.5px var(--text-hover);
    position: absolute;
    left: 4px;
    top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.tag_check span {
    width: 10px;
    height: 10px;
    background-color: var(--purple);
    border-radius: 2px;
}

.tag_name {
    font-size: 14px;
    font-weight: 700;
}

@media only screen and (max-width: 1023px) {
    .title {
        display: none;
    }

    .container {
        border-bottom: none;
        background-color: var(--grey_2);
        border-radius: 8px;
    }

    .province_cnt {
        width: 100vw;
        height: 75vh;
    }

    .province_name {
        width: calc(100% - 26px);
    }

    .price_list_cnt {
        display: flex;
    }

    .province_bottom {
        padding: 0px 12px 18px 12px;
    }

    .province_btn_apply {
        width: 100%;
    }

    /* tag */
    .tags_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tag_item_cnt {
        width: calc(50% - 6px);
        padding: 6px;
        margin: 4px 0px;
        background-color: var(--bg-color);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        transition: var(--trans);
        border: solid 1px var(--bg-color);
    }

    .tag_item_cnt_img {
        display: block;
        position: relative;
        width: 44px;
        height: 44px;
        border-radius: 4px;
        background-color: var(--bg-color);
    }

    .tag_item_cnt_img img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 4px;
        object-fit: cover;
    }

    .tag_check {
        right: 1px;
        left: unset;
        border: none;
    }

    .tag_check span {
        position: absolute;
        z-index: 3;
        width: 22px;
        height: 22px;
        background-color: var(--purple);
        top: -7px;
        border-radius: 0px 4px 0px 0px;
        clip-path: polygon(100% 0, 0 0, 100% 100%);
    }

    .tag_check span img {
        width: 9px;
        margin: 4px 0px 0px 12px;
    }

    .tag_item_cnt_act {
        background-color: transparent;
        border: solid 1px var(--purple);
        transition: var(--trans);
    }

    .tag_name_act {
        color: var(--bg-white);
    }

    .tag_name {
        width: calc(100% - 50px);
    }

    /* filter tag service */
    .tag_ser_container {
        background-color: var(--grey_2);
        padding: 6px;
        border-radius: 8px;
    }

    .tag_ser_title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
    }

    .tag_ser_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .tag_ser_item {
        width: calc(50% - 6px);
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0px;
        background-color: var(--bg-color);
        border-radius: 6px;
        position: relative;
    }

    .tag_ser_item_select {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 6px;
        border: solid 1px var(--purple);
        z-index: 3;
    }

    .tag_ser_item_select::after {
        position: absolute;
        content: '';
        width: 22px;
        top: -1px;
        right: 0;
        height: 22px;
        background-color: var(--purple);
        border-radius: 0px 6px 0px 0px;
        clip-path: polygon(100% 0, 0 0, 100% 100%);
    }

    .tag_ser_item_select img {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 9px;
        z-index: 4;
    }

    .tag_ser_item_child {
        position: absolute;
        width: calc(200% + 12px);
        z-index: 2;
        margin-left: calc(-100% - 12px);
        left: 0;
        top: 50px;
        background-color: var(--bg-color);
        padding: 6px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        visibility: hidden;
        opacity: 0;
    }

    .item_child_show {
        visibility: visible;
        opacity: 1;
    }

    .tag_ser_item_img {
        width: 44px;
        height: 44px;
        position: relative;
        background-color: var(--bg-color);
        border-radius: 6px;
    }

    .tag_ser_item_img img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        object-fit: cover;
        border-radius: 6px;
    }

    .tag_ser_item_text {
        font-size: 14px;
        font-weight: 700;
        color: var(--text-black);
        width: calc(100% - 50px);
        text-transform: capitalize;
    }

    /* end filter tag service */
}