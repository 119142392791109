.memberContainer {
    width: 100%;
    margin: 0 auto;
}
.member,
.memberRank {
    margin: 0 auto;
}

.memberTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.memberTitle {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}
.memberSeemore {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}
.memberBot {
    margin-top: 16px;
}
.memberInfoWrap {
    display: flex;
    align-items: center;
}
.memberAvatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
}
.memberInfo {
    margin-left: 10px;
}
.memberName {
    font-weight: 700;
}
.memberNumber {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
}
.memberProgress {
    margin-top: 16px;
    border-radius: 8px;
    padding: 14px;
    background-color: var(--purple);
}
.memberProgressRank {
    text-align: right;
    color: #fff;
    font-weight: 500;
}
.progressBar {
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.748);
    margin: 20px 0;
    position: relative;
}
.progressBar::after,
.progressBar::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid #fff;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
}
.progressBar::after {
    background-color: #f5c031;
    left: -1px;
}
.progressBar::before {
    background-color: #ff721f;
    right: -1px;
}
.progressPercent {
    height: 100%;
    width: 80%;
    background: linear-gradient(90deg, #f5c031 0%, #ff721f 100%);
    position: absolute;
    top: 0;
    left: 0;
}
.memberProgressInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.memberProgressInfo > p {
    color: #fff;
    font-weight: 500;
}
.memberProgressInfo > p > span {
    font-weight: 700;
}

.memberRank {
    margin-top: 24px;
}
.memberTable {
    margin-top: 16px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}
.tableHeader {
    padding: 16px;
    background: linear-gradient(90deg, #ff721f 0%, #f5c031 100%);
}
.tableHeaderTitle {
    color: #fff;
    font-weight: 700;
}
.tableTabList {
    display: flex;
}
.tableTabItem {
    padding: 16px;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s;
    text-align: center;
    border-bottom: 1px solid #ccc;
}
.tableTabItem:hover > p {
    color: #f5c031;
    transition: all 0.2s;
}
.styleTabletTitle {
    font-weight: bold;
    color: var(--text-black);
}
.contentItems {
    flex-direction: column;
}
.contentItem {
    padding: 16px 0;
    border-bottom: 1px solid #ccc;
}
.itemTitle {
    font-weight: 700;
}
.contentItem:not(:first-child) > .itemTitle > .itemText {
    font-weight: 300;
}
@media only screen and (max-width: 1024px) {
    .member,
    .memberRank {
        width: 100%;
    }
}
