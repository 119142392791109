/* remake map org */
.head {
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head_back {
    padding: 8px;
    background-color: var(--white);
}

.head_back:hover {
    background-color: var(--bg-color);
}

.head_back img {
    transform: rotate(180deg);
}

.head_title {
    font-size: 16px;
    font-weight: 600;
}

.body {
    padding: 12px;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: space-between;
}

.left {
    width: 33.33%;
    height: 100%;
    overflow-y: scroll;
    padding: 0px 12px;
}

.right {
    width: 100%;
    height: 100%;
}

.org_map_marker_item {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.org_map_marker_item img {
    transform: scale(0.9);
}

.org_map_marker_item span {
    background-color: var(--bgWhite);
    box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.27);
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin-top: 6px;
}


.map_org_list_item {
    width: 100%;
    margin: 12px 0px;
}

.map_card_item {
    padding: 8px;
    border-radius: 6px;
    background-color: var(--grey_2);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: var(--trans);
}

.map_card_item:hover {
    background-color: var(--bg-gray-1);
    transition: var(--trans);
}

.map_card_item_img {
    width: 84px;
    height: 84px;
    border-radius: 6px;
}

.map_card_item_detail {
    width: calc(100% - 84px);
    padding-left: 6px;
    display: flex;
    flex-direction: column;
}

.name {
    font-size: 13.5px;
    line-height: 18px;
    font-weight: 700;
}

.address {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-top: 6px;
    color: var(--text-black);
}

.map_org_list_wrapper_mb {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .head{
        padding: 7px 12px;
    }
    .body {
        padding: 0px;
    }

    .left {
        display: none;
    }

    .right {
        width: 100%;
    }

    .map_org_list_wrapper_mb {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px 0px;
        z-index: 2;
    }

    .map_card_item {
        margin: 0px 8px;
        background-color: var(--white);
        box-shadow: var(--box-shadow);
    }
}