.container {
  width: 100%;
}

.head {
  position: fixed;
  z-index: 100;
  padding: 12px 0px;
  width: 100%;
  background-color: var(--white);
}

.head_back_btn {
  position: absolute;
  z-index: 1;
  padding: 8px;
  background-color: var(--white);
  left: 12px;
  top: 18px;
}

.head_back_btn img {
  transform: rotate(180deg);
}

.head_back_btn:hover {
  background-color: var(--bg-color);
}

.tab_container {
  background-color: var(--bg-gray-1);
  padding: 8px 8px;
  border-radius: 6px;
  display: flex;
}

.tab_container a {
  display: block;
  transition: var(--trans);
  padding: 6px 8px;
  border-bottom: solid 1px transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}

.tab_act {
  color: var(--purple);
  border-bottom: solid 1px var(--purple) !important;
}

.page_container {
  padding: 75px 0px 12px 0px;
  position: relative;
}

.left {
  position: absolute;
  width: calc(20% - 12px);
  max-height: calc(100vh - 87px);
  overflow-y: scroll;
}

.cate_item {
  padding: 2px 0px;
}

.cate_link {
  display: block;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 6px 8px;
  transition: var(--trans);
}

.cate_link:hover {
  background-color: var(--bg-gray);
  transition: var(--trans);
}

.cate_link_act {
  transition: var(--trans);
  color: var(--white);
  background-color: var(--purple);
}

.cate_link_act:hover {
  background-color: var(--purple);
}

.right {
  width: 80%;
  margin-left: 20%;
}

.list {
  grid-gap: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

@media only screen and (max-width: 992px) and (min-width: 767px) {}

@media only screen and (max-width: 767px) {
  .head {
    padding: 0px;
  }

  .page_container {
    padding: 51px 0px 12px 0px;
  }

  .tab_container {
    padding: 9px 8px;
    background-color: var(--grey_2);
    justify-content: center;
  }

  .tab_container a {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }

  .head_back_btn {
    background-color: #dcdbdb8c;
    top: 8px;
    left: 12px;
    border-radius: 100%;
  }

  .left {
    width: 100%;
    overflow-x: scroll;
    overflow-y: unset;
    min-height: unset;
    z-index: 10;
    background-color: var(--grey_2);
    padding: 6px 12px;
  }

  .cate_list {
    display: flex;
    flex-direction: row;
    width: max-content;
  }

  .right {
    width: 100%;
    margin-left: 0px;
    padding: 50px 12px 0px 12px;
  }

  .list {
    grid-gap: 12px;
    display: grid;
    grid-template-columns: repeat(1, 1fr)
  }

  .item {
    height: 100px;
  }

}