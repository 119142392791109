.back-top-btn {
    position: fixed;
    bottom: 48px;
    right: 24px;
    z-index: 5;
    background-color: var(--orange);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.5s;
    visibility: hidden;
    margin-bottom: 38px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-top-btn__act {
    visibility: visible;
    margin-bottom: 32px;
    transition: all 0.5s;
    opacity: 1;
}

@media only screen and (max-width: 1023px) {
    .back-top-btn {
        right: 12px;
        bottom: 12px;
    }

    .back-top-btn__act {
        margin-bottom: 20px;
    }
}
