.title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: var(--black);
}

.choose_method {
    margin-top: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.choose_method span {
    background-color: var(--pink-momo);
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--bg-white);
}

.drawer_cnt {
    height: 100%;
    width: 360px;
    background-color: var(--bg-white);
    padding: 12px;
}

.method_item {
    padding: 4px 8px;
    margin: 4px 0px;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    transition: var(--trans);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0px;
}
.method_item img{
    width: 45px;
}
.method_item_content{
    width: calc(100% - 50px);
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}
.method_availability{
    position: absolute;
    background-color: var(--green);
    width: max-content;
    font-size: 10px;
    line-height: 11px;
    padding: 1px 4px;
    border-radius: 6px;
    top: -1px;
    right: -38px;
    color: var(--white);
}

.method_item_img{
    border-radius: 4px;
}