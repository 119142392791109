.cate_list_cnt {
    margin-top: 32px;
    padding-bottom: 32px;
    width: 100%;
}

.next_btn,
.prev_btn {
    position: absolute;
    right: 0;
    top: 0;
    margin: 88px -10px 0px 0px;
    background-color: var(--bg-white);
    width: 24px;
    height: 24px;
    padding: 0px;
    border-radius: 100%;
    box-shadow: var(--box-shadow);
    z-index: 10;
}

.prev_btn {
    position: absolute;
    top: 0;
    left: 0;
    margin: 88px 0px 0px -10px;
}

.next_btn:hover,
.prev_btn:hover {
    transform: scale(1.6);
}

.cate_list {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 16px;
}


.cate_link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cate_link_img {
    width: 55px;
    height: 55px;
    background-color: var(--bg-color);
    border-radius: 20px;
    position: relative;
}

.cate_link_img img {
    border-radius: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.cate_link_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    text-transform: capitalize;
}