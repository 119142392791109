.container{
    width: 100%;
    height: 86px;
    border-radius: 6px;
    background-color: var(--bg-white);
    box-shadow: var(--box-shadow);
    display: flex;
}
.item_img_cnt{
    height: 100%;
    width: 86px;
    border-radius: 6px;
    background-color: var(--bg-color);
    position: relative;
    overflow: hidden;
}
.item_img{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.item_detail{
    width: calc(100% - 86px);
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.item_name{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: var(--text-black);
}
.item_price{
    font-size: 13px;
    color: var(--text-hover);
    font-weight: 500;
}
.item_price span:first-child{
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;   
    color: var(--text-orange);
    margin-right: 4px;
}
.item_price :nth-child(2){
    text-decoration: line-through;
}