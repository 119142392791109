.container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}
.left{
  width: 33.33%;
}
.right{
  width: 66.66%;
  height: 100%;
}