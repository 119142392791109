.guided-section {
    background: #f5f5f7;
}
.guided-section-title {
    padding: 12px 24px;
    background-color: var(--purple);
    text-align: center;
}
.guided-section-title h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    min-width: 255px;
    width: 70%;
    margin: auto;
    color: var(--bgWhite);
}
.guided-section-step:first-child {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}
.guided-section-step {
    scroll-snap-align: center;
    display: flex;
    padding: 0 12px;
}
.step-content {
    width: 70%;
}
.step-slide {
    width: 30%;
}

.guided-section-step:last-child {
    scroll-snap-align: end;
}
.guided-section-step .step {
    background-color: var(--purple);
    color: var(--bgWhite);
    padding: 4px 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    max-width: 100px;
    width: fit-content;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 8px;
}
.guided-section-step .step-desc {
    padding: 0 12px 0 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--black);
    margin-bottom: 17px;
}
.guided-section-content {
    height: 100%;
    margin-top: 58px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.step-img__model {
    width: 250px;
    height: 250px;
    margin: 0 auto;
}
@media only screen and (max-height: 667px) {
    .guided-section-content {
        scroll-snap-type: unset;
    }
}
.guided-section-step .step-slide .step-img img {
    margin: auto;
    max-width: 95%;
}

.guided-section-step .step-slide .step-img-desc span {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 248px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #6f6f6f;
}
/* slider section */
.step-slider.active {
    opacity: 1;
    z-index: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    visibility: visible;
    position: fixed;
    z-index: 100;
    display: flex;
}
.step-slider {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    display: none;
    transition: all 0.3s ease-in-out;
    inset: 0;
    align-items: center;
    justify-content: center;
}
.step-slider .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
}
.step-slider .slick-slider {
    width: 100%;
    z-index: 2;
    padding: 0 20px;
}
.step-slider .slick-slider .slick-slide {
    transition: all 0.3s ease-in-out;
}
.step-slider .slick-slide img {
    width: 100%;
    height: 100%;
}
.step-slider .step-img-desc {
    padding-top: 20px;
    text-align: center;
    color: var(--bgWhite);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}
.step-slider .slick-slider .slick-dots li button:before {
    color: var(--bgWhite) !important;
}
.step-slider .slick-slider .slick-prev {
    left: 5px;
}
.step-slider .slick-slider .slick-next {
    right: 5px;
}
.bg-shadow {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}
