.container {
    background-color: var(--bg-white);
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: space-between;
    max-width: 520px;
    padding: 12px;
    position: relative;
    width: 70vw;
}

.body {
    width: 100%;
    height: calc(100% - 38px);
    overflow-y: scroll;
    padding-bottom: 70px;
}
.tip_cnt{
    display: flex;
    align-items: center;
    background-color: #FFE3D2;
    padding: 10px 8px;
    margin-bottom: 12px;
    border-radius: 8px;
}
.tip_cnt img{
    width: 32px;
    height: 32px;
    margin-right: 8px;
}
.tip_cnt span{
    font-size: 14px;
    font-weight: 600;
    color: var(--text-black);
}
.tip_media{
    padding: 10px 0px;
    display: flex;
    align-items: center;
}
.tip_media img{
    width: 28px;
    height: 28px;
    margin-right: 6px;
    margin: 6px 0px;
}
.tip_media span{
    font-size: 14px;
    font-weight: 600;
    color: var(--text-black);
}
.org_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.item {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
}

.item_img {
    width: 64px;
    height: 64px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
}

.item_img img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 1;
    object-fit: cover;
}

.item_name {
    width: calc(100% - 70px);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.rate_star {
    padding: 6px 0px;
}

.title,
.star_item_label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}
.title{
    font-size: 13px;
    font-weight: 600;
}



.text_area {
    margin-top: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    max-width: 100%;
    min-height: 100px;
    min-width: 100%;
    padding: 8px;
}

.body_media {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.body_media_btn {
    align-items: center;
    background-color: #c9c0ff;
    border-radius: 6px;
    display: flex;
    padding: 4px 8px;
    width: fit-content;
    color: var(--purple);
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

.body_media_btn img {
    margin-right: 8px;
}

.media_img_cnt {
    margin-top: 6px;
    width: 100%;
}

.list_img {
    grid-gap: 8px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.img_cnt {
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
}
.skelton{
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    border-radius: 6px;
}
.img_cnt_remove {
    position: absolute;
    z-index: 3;
    background-color: var(--bg-white);
    padding: 2px;
    border-radius: 100%;
    top: -6px;
    right: -6px;
}

.img_item_temp {
    position: absolute;
    z-index: 1;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 8px 12px 8px 12px;
    box-shadow: var(--box-shadow);
    background-color: var(--bg-white);
    z-index: 10;
}

.bottom_btn {
    width: 100%;
    border-radius: 6px;
    background-color: var(--text-orange);
}
.service_item_cnt{
    width: 100%;
    padding: 12px;
    box-shadow: var(--box-shadow);
    border-radius: 6px;
}
.service{
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
}

@media only screen and (max-width: 1023px) {
    .container {
        margin-top: 44px;
        max-width: unset;
        height: 100%;
        width: 100%;
        padding-bottom: 70px;
    }

    .body {
        height: max-content;
        overflow-y: unset;
    }
    .star_item_label{
        font-size: 12px;
        margin-top: 6px;
    }

    .bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 8px 12px 20px 12px;
        border-radius: 6px 6px 0px 0px;
    }
}