.input_cnt {
  position: relative;
  height: 80px;
}

.input_box {
  width: 100%;
  background-color: var(--grey_2);
  position: relative;
}
.input_box_outlined{
  box-shadow: inset 0 2px 6px rgb(113 97 186 / 20%);
  background-color: var(--white);
}

.input_icon {
  margin-left: 4px;
  position: absolute;
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_text {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 500;
  padding-right: 16px;
}

.input_right {
  position: absolute;
  height: 100%;
  aspect-ratio: 1/1;
  top: 0;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input_text_error {
  position: absolute;
  padding: 4px 0px 0px 12px;
  color: var(--red-cl);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}