.container {
  padding-bottom: 12px;
  background-color: var(--white);
  padding: 12px;
  border-radius: 8px;
}
.title{
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 6px;
  border-bottom: solid 1px var(--grey_2);
}

.list_item {
  margin-top: 12px;
  grid-gap: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.bottom{
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
.bottom a{
  display: block;
  background-color: var(--pr-green);
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
}
.bottom a:hover{
  filter: saturate(150%);
}
.bottom a img{
  transform: rotate(-90deg);
  margin: 2px 0px 0px 4px;
}
@media only screen and (max-width: 992px) and (min-width: 767px) {}

@media only screen and (max-width: 767px) {

  .list_item{
    grid-gap: 12px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .item{
    height: 100px;
  }
}