.result-orgs-loading-cnt {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.result-orgs-loading-cnt__item {
    width: 20%;
    height: 282px;
    max-height: unset;
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--grey_2);
}

.result-orgs-loading-cnt__item .MuiSkeleton-root:nth-child(1) {
    width: 100% !important;
    height: 100% !important;
}

.result-orgs-loading-cnt__item .MuiSkeleton-root:nth-child(2) {
    height: 100px !important;
}

.result-orgs-loading-cnt .service-item {
    width: 16.6%;
    max-height: unset !important;
}

.result-orgs-loading-cnt-row {
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item .MuiSkeleton-root:nth-child(1) {
    width: 146px !important;
    height: calc(7% - 4px) !important;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item .MuiSkeleton-root:nth-child(2) {
    height: auto !important;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item {
    height: 262px;
}

.result-orgs-loading-cnt-row::-webkit-scrollbar {
    display: none;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item.service-item span {
    width: 146px;
    height: calc(7% - 4px);
    transform: unset;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item.service-item span:nth-child(1) {
    height: calc(77% - 10px) !important;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item.service-item span:nth-child(2) {
    height: calc(7% - 4px) !important;
}

.result-orgs-loading-cnt-row .result-orgs-loading-cnt__item.service-item span:nth-child(4) {
    height: calc(10% - 4px) !important;
}

/* load org */
.load-org {
    position: fixed;
    z-index: 10000;
    background-color: var(--white);
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}

.load-org-head {
    width: 100%;
    height: 95px;
    background-color: var(--bg-gray);
}

.load-org-cnt {
    padding-top: 32px;
}

.load-org-cnt__banner {
    width: 100%;
    height: 362px;
}

.load-org-cnt__in {
    padding: 0 32px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}

.load-org-cnt__in-avt {
    width: 140px;
    height: 140px;
    border-radius: 100%;
    overflow: hidden;
}

.load-org-cnt__in-de {
    padding: 20px 0px;
    width: calc(100% - 160px);
    height: 140px;
}

.load-org-cnt__in-de-name {
    width: 100%;
    height: 28px;
    margin: 8px 0px;
}

.load-org-cnt__time {
    margin-top: 6px;
}

.load-org-cnt__time-left {
    width: 350px;
    height: 32px;
}

.load-org-cnt__time-right {
    width: 324px;
    height: 42px;
}

.load-org-cnt__tab {
    margin-top: 6px;
}

.load-org-cnt__tab-item {
    width: 60px;
    height: 32px;
    margin-right: 16px;
}

.load-org-services {
    padding: 28px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.load-org-services__item {
    width: 16.6%;
    height: 274px;
    padding: 8px;
}

/* load detail */
.load-detail__head {
    margin-top: 48px;
    width: 100%;
    height: 506px;
    /* background-color: burlywood; */
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.load-detail__head .left {
    width: 450px;
    height: 450px;
}

.load-detail__head .right {
    height: 450px;
    width: calc(100% - 464px);
}

.load-detail__head .right .org-name {
    width: 80%;
    height: 20px;
}

.load-detail__head .right .detail-name {
    width: 100%;
    height: 72px;
    margin: 8px 0px;
}

.load-detail__head .right .detail-rate {
    width: 50%;
    height: 18px;
    margin-top: 16px;
}

.load-detail__head .right .detail-card-cnt {
    width: 100%;
    height: 177px;
    margin: 24px 0px;
}

.load-detail__head .right .detail-bottom {
    width: 100%;
    height: 40px;
    margin-top: 74px;
}

.load-detail__head .right .detail-bottom .quantity {
    height: 100%;
    width: 110px;
}

.load-detail__head .right .detail-bottom .buy {
    width: 370px;
    height: 100%;
}

.load-detail__desc {
    width: 100%;
    height: 200px;
    margin-top: 24px;
}

/* load-home-banner */
.load-home-banner {
    display: flex;
    justify-content: space-between;
    margin: 32px 0px;
    height: 350px;
}

.load-home-banner__img {
    width: 840px;
    height: 100%;
}

.load-home-banner__img img {
    width: 100%;
    height: 100%;
}

.load-home-banner__right {
    width: calc(100% - 840px);
    height: 100%;
    padding-left: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
}
.load-home-banner__right-item{
    border-radius: 20px;
    overflow: hidden;
}

.load-home-tag {
    width: 100%;
}

.load-home-tag .tag-list {
    display: grid !important;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 16px;
}

.load-home-tag .tag-list .item {
    height: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.item_load_img{
    width: 55px;
    height: 55px;
    background-color: var(--bg-color);
    border-radius: 20px;
}
.item_load_text{
    width: 100%;
    height: 18px;
    background-color: var(--bg-color);
}

/* load map orgs */
.load-org-cnt {
    background-color: #E6E4E0;
    width: 100vw;
    height: 100vh;
}

.load-org-cnt__left {
    height: 100%;
    background-color: var(--bgWhite);
    width: 20%;
    overflow-y: scroll;
}

.load-org-cnt__left .list {
    height: max-content;
}

.load-org-cnt__left .list-item__cnt {
    padding: 12px;
    height: 95px;
    width: 100%;
}

.load-org-cnt__left .list-item__cnt-img {
    width: 70px;
    height: 70px;
}

.load-org-cnt__left .list-item__cnt-de {
    height: 100%;
    width: calc(100% - 70px);
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.load-org-cnt__left .list-item__cnt-de span {
    width: 100%;
    height: 20px;
}

/* load skeleton */
.load_grid_list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
}

.load_grid_list_item {
    height: 316px;
    border-radius: 8px;
}

.load_grid_list_item_img {
    width: 100%;
    height: 179px;
    border-radius: 8px 8px 0px 0px;
}

.load_grid_list_item_detail {
    padding: 8px;
    border-radius: 0px 0px 8px 8px;
    background-color: var(--bgWhite);
    height: calc(100% - 179px);
}

.load_grid_list_item_detail .name {
    width: 100%;
    height: 32px;
}

.load_grid_list_item_detail .address {
    width: 100%;
    height: 56px;
    margin-top: 4px;
}
/* load progress */


@media only screen and (max-width: 1023px) {
    .result-orgs-loading-cnt__item {
        width: 50%;
    }

    .result-orgs-loading-cnt .service-item {
        width: 100%;
        height: 111px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .result-orgs-loading-cnt .service-item .MuiSkeleton-root:nth-child(2) {
        display: none !important;
    }

    .load-org-head {
        display: none;
    }

    .load-org-cnt {
        padding: 0px;
    }

    .load-org-cnt__banner {
        height: 184px;
    }

    .load-org-cnt__in {
        background-color: var(--grey_2);
        margin-top: 0px;
        padding: 12px 24px 0px 24px;
    }

    .load-org-cnt__in-avt {
        width: 60px;
        height: 60px;
        border: solid 1px var(--white);
    }

    .load-org-cnt__in-de {
        padding: 0px;
        width: calc(100% - 64px);
        height: 90px;
    }

    .load-org-cnt__in-de-name {
        margin: 2px 0px;
        height: 24px;
    }

    .load-org-cnt__time {
        display: flex;
        flex-direction: column-reverse;
    }

    .load-org-cnt__time-right,
    .load-org-cnt__time-left {
        width: 88%;
        margin-bottom: 6px;
    }

    .load-org-cnt__tab-wrap {
        width: 100%;
        overflow: scroll;
        padding-left: 12px;
    }

    .load-org-cnt__tab {
        width: fit-content;
    }

    .load-org-services {
        padding: 12px 4px;
    }

    .load-org-services__item {
        width: 100%;
        height: 76px;
    }

    /* detail service */
    .load-detail__head {
        margin: 0px;
        padding: 0px;
        flex-direction: column;
        height: fit-content;
    }

    .load-detail__head .right {
        display: none;
    }

    .load-home-banner {
        margin: 0px;
        height: fit-content;
    }

    .load-home-banner__img {
        width: 100%;
        height: 170px;
    }

    .load-home-banner__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .load-home-banner__right {
        display: none;
    }

    .load-home-tag {
        width: 100%;
        overflow-y: scroll;
    }

    .load-home-tag .tag-list {
        margin-top: 16px;
    }

    .load-home-tag .tag-list .item {
        width: 96px;
        height: 80px;
        padding: 0px;
        margin: 0px 6px;
    }

    .load-home-tag .tag-list {
        width: fit-content;
    }

    .load-org-cnt__left {
        position: fixed;
        height: 170px;
        width: 100%;
        z-index: 2;
        bottom: 0;
    }

    .load_grid_list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}