.wrapper {
    width: 100vw;
    max-width: 500px;
    padding: 12px;
}

.container {
    backdrop-filter: blur(10px);
    background-color: #ffffff99;
    width: 100%;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    background-color: var(--bg-white);
    position: relative;
}

.left img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.right {
    width: calc(100% - 88px);
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right_title {
    font-size: 14px;
    font-weight: 700;
}

.right_bot {
    display: flex;
    justify-content: flex-end;
}

.right_btn {
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 600;
}
.right_btn:nth-child(2){
    margin-left: 8px;
}