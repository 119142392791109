.container {
    background-color: var(--bg-white);
    display: flex;
    flex-direction: column;
    height: 95vh;
    max-width: 520px;
    padding: 12px;
    position: relative;
    width: 70vw;
    overflow-y: hidden;
}

.body {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 100px;
}

.panel {
    padding: 18px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.panel_icon {
    margin-right: 8px;
}

.panel_content {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: var(--bg-white);
}

.order_user {
    margin-top: 12px;
    background-color: #FAFAFA;
    padding: 4px 12px;
    border-radius: 6px;
}

.order_user_head {
    display: flex;
    align-items: center;
}

.order_user_head span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-left: 8px;
}

.order_user_body {
    padding-left: 28px;
}

.order_user_body_row {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
}

.order_item_cnt {
    padding: 4px 0px;
    margin-top: 8px;
}

.order_item_cnt_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.org_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    transition: var(--trans);
    display: flex;
    align-items: center;
}
.org_name img{
    margin-left: 6px;
    width: 16px;
    height: 16px;
}

.org_name:hover {
    opacity: 0.5;
    transition: var(--trans);
}

.order_status {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
}

.list {
    margin-top: 8px;
}

.list_item {
    padding: 7px 0px;
    position: relative;
}
.list_item_block{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff87;
    z-index: 11;
    top: 0;
    padding: 7px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 11px;
    font-weight: 500;
    color: var(--red_2);
}

.amount {
    margin-top: 7px;
    padding: 10px 12px;
    background-color: #FAFAFA;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.amount_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
}

.amount_number {
    margin-left: 4px;
    display: flex;
    align-items: center;
}

.amount_number span {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}

.amount_number span:nth-child(2) {
    text-decoration: line-through;
    margin-left: 4px;
    font-weight: 500;
}

.order_created {
    margin-top: 12px;
}

.order_created_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.order_created_row_label,
.order_created_row_value {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.order_created_row_value {
    font-weight: 600;
}

.bottom {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    background-color: var(--bg-white);
    width: 100%;
    padding: 8px 12px;
    box-shadow: var(--box-shadow);
}

.bottom_btn {
    width: 100%;
    border-radius: 6px;
    background-color: var(--text-orange);
}

@media only screen and (max-width: 1023px) {
    .container {
        width: 100%;
        height: 100%;
        overflow-y: unset;
        padding: 44px 0px 0px 0px;
    }

    .body {
        height: max-content;
        padding-bottom: 100px;
    }

    .panel {
        border-radius: 0px;
    }

    .order_user {
        margin: 0px;
        padding: 12px;
    }

    .order_item_cnt {
        margin: 0px;
        padding: 12px;
    }

    .order_created {
        padding: 12px;
        margin-top: 0px;
    }

    .bottom {
        padding: 8px 12px 20px 12px;
        border-radius: 6px 6px 0px 0px;
    }

    .scan_btn {
        padding: 0px;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        margin-left: 6px;
        background-color: transparent;
    }
}