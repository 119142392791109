.container {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 80px;
    right: 24px;
    border-radius: 100px;
    z-index: 10;
    box-shadow: 0px 0px 4px 0px rgba(255, 80, 181, 0.76);
    border: solid 1px var(--text-orange);
}

.navigate_map_btn {
    padding: 0px;
    background-color: transparent;
    border-radius: 100%;
}

.navigate_map_btn img {
    margin: 0px;
    width: 100%;
}

@media screen and (max-width: 767.98px) {
    .container{
        bottom: 140px;
        right: 12px;
    }
}
