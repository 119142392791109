.active_btn {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s linear;
}

.assistantBtn {
    transition: all 0.2s linear;
}

#floating-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--purple);
    position: fixed;
    bottom: 24px;
    right: 24px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.plus {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    opacity: 1;
    margin: auto;
    border-radius: 100px;
    overflow: hidden;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    transform: rotate(0deg);
    animation: plus-out 0.3s;
    transition: all 0.3s;
    background-color: #fff;
}

.plus img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.plus-hover:hover {
    transform: rotate(90deg);
    animation: plus-in 0.3s;
    transition: all 0.3s;
}

.assistantBtn-wrap {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 24px;
    border-radius: 100px;
    right: 24px;
    z-index: 50;
}

.assistantBtn-wrap-hover:hover {
    height: 250px;
    width: 40px;
    padding: 30px;
    right: 15px;
}

.assistantBtn-wrap-active {
    height: 250px;
    width: 40px;
    padding: 30px;
    right: 15px;
}

.assistantBtn-wrap-hover:hover .plus {
    animation: plus-in 0.15s linear;
    animation-fill-mode: forwards;
}

.edit {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    right: 0;
    padding: 0;
    opacity: 0;
    margin: auto;
    line-height: 65px;
    transform: rotateZ(-70deg);
    transition: all 0.3s;
    animation: edit-out 0.3s;
    width: 14px;
    height: 14px;
}

.assistantBtn-wrap-hover:hover .edit {
    animation: edit-in 0.2s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
}

.buttons {
    border: none;
    outline: none;
}

@keyframes edit-in {
    from {
        opacity: 0;
        transform: rotateZ(-70deg);
    }

    to {
        opacity: 1;
        transform: rotateZ(0deg);
    }
}

@keyframes edit-out {
    from {
        opacity: 1;
        transform: rotateZ(0deg);
    }

    to {
        opacity: 0;
        transform: rotateZ(-70deg);
    }
}

@keyframes plus-in {
    from {
        opacity: 1;
        transform: rotateZ(0deg);
    }

    to {
        opacity: 0;
        transform: rotateZ(180deg);
    }
}

@keyframes plus-out {
    from {
        opacity: 0;
        transform: rotateZ(180deg);
    }

    to {
        opacity: 1;
        transform: rotateZ(0deg);
    }
}

.buttons {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: fixed;
    z-index: 300;
    transform: scale(0);
    cursor: pointer;
}

.btn1 {
    background: #13b139;
    right: 24px;
    bottom: 80px;
    animation-delay: 0.2s;
    animation: bounce-out-buttons 0.3s linear;
    animation-fill-mode: forwards;
}

.btn2 {
    background: var(--orange);

    right: 24px;
    bottom: 137px;
    animation-delay: 0.15s;
    animation: bounce-out-buttons 0.15s linear;
    animation-fill-mode: forwards;
}

.btn3 {
    background: var(--red-cl);
    right: 24px;
    bottom: 192px;
    animation-delay: 0.1s;
    animation: bounce-out-buttons 0.1s linear;
    animation-fill-mode: forwards;
}

.btn-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes bounce-buttons {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounce-out-buttons {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

.assistantBtn-wrap-hover:hover .buttons {
    animation: bounce-buttons 0.1s linear;
    animation-fill-mode: forwards;
}

.assistantBtn-wrap-hover:hover .btn1 {
    animation-delay: 0.08s;
}

.assistantBtn-wrap-hover:hover .btn2 {
    animation-delay: 0.15s;
}

.assistantBtn-wrap-hover:hover .btn3 {
    animation-delay: 0.2s;
}

@media screen and (max-width: 1023px) {
    #floating-button {
        bottom: 90px;
        right: 12px;
    }

    .btn3,
    .btn2,
    .btn1 {
        right: 12px;
    }

    .btn1 {
        bottom: 140px;
    }

    .btn2 {
        bottom: 190px;
    }

    .btn3 {
        bottom: 240px;
    }

    .assistantBtn-wrap {
        width: 40px;
        height: 40px;
        bottom: 90px;
        right: 13px;
        border-radius: 100px;
    }

    .assistantBtn-wrap-hover:hover {
        height: 200px;
        width: 40px;
        padding: 30px;
        right: 0px;
    }
}