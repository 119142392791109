.container{
  width: 100%;
  height: 75vh;
  background-color: var(--bgWhite);
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button{
  background-color: var(--purple);
  margin-top: 44px;
  font-size: 13px;
  padding: 8px 12px;
  border-radius: 16px;
  font-weight: 600;
  color: var(--bgWhite);
}
@media only screen and (max-width: 1023px) {
  .container{
    height: 100vh;
  }
}