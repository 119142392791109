.container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    position: relative;
}

.org_on_e {
    position: absolute;
    z-index: 2;
    width: 28px;
    height: 28px;
    margin: -6px 0px 0px -10px;
    display: flex;
    align-items: center;
}

.org_on_e_icon {
    margin: auto;
    transform: scale(1.6);
}

.img_container {
    position: relative;
    padding-bottom: 100%;
    background-color: var(--bg-color);
    border-radius: 8px 8px 0px 0px;
}

.org_img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
}

.org_react_cnt {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    bottom: 0px;
}

.org_react_cnt_heart {
    display: flex;
    align-items: center;
    background-color: var(--bg-white);
    padding: 2px 5px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}

.org_react_cnt_heart_count {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    margin-right: 6px;
}

.org_react_cnt_heart_icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
}

.org_detail {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    padding: 6px;
    display: flex;
    flex-direction: column;
}

.org_name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--text-black);
    display: -webkit-box;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.org_address {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: grey;
    display: -webkit-box;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.org_bot {
    margin-top: 4px;
    display: flex;
    justify-content: flex-end
}

.org_bot_star {
    margin: 0px 9px;
    display: flex;
    align-items: center;
}

.org_bot_icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
}

.org_branch_btn {
    padding: 2px 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--purple);
    background-color: var(--bg-white);
    border: solid 1px var(--purple);
    border-radius: 6px;
}

.org_map_btn,
.org_bot_text {
    color: var(--text-black);
    border: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
}

/* change style */
.container_ch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
}

.img_container_ch {
    width: 64px;
    height: 64px;
    padding-bottom: 0%;
    border-radius: 100%;
}

.org_react_cnt_ch {
    right: calc(-100vw + 102px);
    top: -10px;
    height: 32px;
    padding: 0px;
}

.org_img_ch {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.org_detail_ch {
    width: calc(100% - 72px);
    padding: 0px;
    justify-content: space-between;
}

.org_react_cnt_heart_icon_ch {
    width: 24px;
    height: 24px;
    margin-left: 6px;
}

.org_name_ch {
    width: calc(100% - 40px);
    -webkit-line-clamp: 1;
}

.org_bot_ch {
    justify-content: flex-start;
}

@media only screen and (max-width: 1023px) {
    .org_react_cnt_heart {
        box-shadow: none;
        background-color: var(--bg-color);
        padding: 5px;
        border-radius: 100%;
    }
}