.page-not-found {
    position: fixed;
    z-index: 1000000;
    background-color: var(--white);
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-not-found-cnt {
    width: fit-content;
    margin: 0px auto;
}

.page-not-found__head h1 {
    font-size: 112px;
    color: var(--orange);
}

.page-not-found__head img {
    width: 112px;
    height: 112px;
    animation: spin 4s linear infinite;
}

.page-not-found-cnt .error-alert {
    font-size: 36px;
    line-height: 54px;
    font-weight: 700;
    margin: 8px;
}

.page-not-found-cnt .error-desc {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}
.page-not-found .error-img {
    padding: 24px 0;
}
.page-not-found-cnt button {
    margin-top: 24px;
    padding: 7px 28px;
    border-radius: 8px;
    background-color: var(--purple);
}
.page-not-found-cnt .error-btn button:nth-child(2) {
    background-color: var(--orange);
}
.page-not-found .error-btn {
    display: flex;
    align-items: center;
    grid-gap: 0 24px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@media screen and (max-width: 767.98px) {
    .page-not-found-cnt .error-alert {
        font-size: 24px;
    }
    .page-not-found-cnt .error-alert {
        line-height: unset;
    }
    .page-not-found-cnt .error-desc {
        font-size: 16px;
        line-height: unset;
    }
}
