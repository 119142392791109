@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
    --bg-white: rgb(255, 255, 255);
    --bg-gray-dark: #e5e5e5;
    --bg-gray-1: #f5f5f5;
    --bg-gray: #f5f5f7;
    --purple: #7161ba;
    --red-cl: #ee6955;
    --text-black: #3b3a40;
    --text-hover: #a2a1ab;
    --text-op: #cac4e5;
    --text-orange: #ff721f;
    --bg-color: #eeedf2;
    --pink: #fff3f1;
    --pink-2: #fbf3f7;
    --pink-momo: #d82d8b;
    --purple-text: #3a2c93;
    --bg-purple: #7266ba;
    --box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.1);
    --trans: all 0.3s;
    --red-2: #de221f;
    --pr-green: #abd372;
    --shadow-2: rgba(0, 0, 0, 0.2);
}

a {
    color: unset;
}

body {
    margin: 0;
    font-family: -apple-system, "Quicksand", "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: pan-x pan-y;
}

* {
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body > iframe {
    display: none;
}

/* scroll bar */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb {
    height: 5px;
    background: #f5f5f5;
    cursor: pointer;
    border-radius: 2px;
}

@media only screen and (max-width: 820px) {
    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-track {
        border-radius: 3px;
        cursor: pointer;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: var(--purple);
        cursor: pointer;
    }

    html {
        scrollbar-width: none;
    }
}

.MuiPaper-root {
    -webkit-overflow-scrolling: touch !important;
    margin: 0px !important;
}

.MuiDrawer-paperAnchorTop {
    background-color: transparent !important;
    box-shadow: none !important;
}

.MuiContainer-root {
    padding: 0px !important;
}

.MuiDialog-paper {
    max-width: unset !important;
    max-height: unset !important;
}

/* .MuiPaper-root{
  width: -webkit-fill-available !important;
} */

.MuiCircularProgress-root {
    color: var(--purple) !important;
}

/* Pagination */

.css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: var(--purple) !important;
}

.css-wjh20t-MuiPagination-ul li {
    font-weight: 700 !important;
}

.css-wjh20t-MuiPagination-ul li:last-child {
    color: var(--purple) !important;
}

.css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root {
    font-weight: 700 !important;
}

.MuiModal-root.MuiDrawer-root {
    z-index: 1301 !important;
}

/* ------ */

html {
    box-sizing: border-box;
    /* scroll-behavior: smooth; */
    /* background: #f5f5f7; */
}

* {
    margin: 0;
    padding: 0;
}

.mt-24 {
    margin-top: 24px;
}

.cursor-pointer {
    cursor: pointer;
}

.flex-row {
    display: flex;
    align-items: center;
}

.flex-row-sp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-column-sp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

input,
textarea,
select,
button {
    outline: none;
}

input {
    line-height: normal;
}

label,
button {
    cursor: pointer;
}

a {
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
}

ol,
ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

/* .MuiModal-root {
    backdrop-filter: blur(10px) !important;
} */

body > iframe {
    position: unset !important;
}

.cursor-pointer {
    cursor: pointer;
}

.linkify-chat {
    text-decoration: underline;
    opacity: 0.8;
    transition: var(--trans);
}

.linkify-chat:hover {
    opacity: 1;
    transition: var(--trans);
}

#cprhliqipldavybercftg .chat-button--img-container {
    display: none !important;
}

.mapboxgl-ctrl-attrib-inner {
    display: none !important;
}

.react-pdf__Document,
.react-pdf__Page{
    display: flex;
    justify-content: center;
    width: 100%;
}
.react-pdf__Page__canvas{
    width: 65% !important;
    height: unset !important;
}

@media only screen and (max-width: 1023px) {
    body {
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }

    html {
        background-color: #fff;
        height: -webkit-fill-available;
    }
}
