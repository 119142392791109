.add_address_btn {
  border: solid 1px var(--purple);
  background-color: var(--white);
}

.container {
  margin-top: 16px;
}

.address_item {
  cursor: pointer;
  padding: 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--trans);
  border: solid 1px var(--grey);
  border-radius: 6px;
}

.address_item_de {
  border: solid 1px var(--purple);
  border-radius: 6px;
  transition: var(--trans);
}

.address_item_left {
  width: calc(100% - 130px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address_name {
  width: calc(100% - 36px);
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.address_item_right {
  display: flex;
  align-items: center;
  transition: var(--trans);
}

.address_item_right button {
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
}

.address_item_right button:nth-child(2) {
  margin-left: 6px;
  background-color: var(--red_2);
}

.input_address_dialog {
  width: 40vw;
  height: 70vh;
  background-color: var(--white);
}

.form_row {
  padding: 10px 0px;
  position: relative;
}

.form_row_label {
  font-size: 13px;
  font-weight: 500;
}

.input_address {
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeedf2;
  outline: none;
  background-color: #eeedf2;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 6px;
  text-align: start;
}
.form_row_label textarea{
  resize: none;
  width: 100%;
  min-height: 150px;
}

.input_select {
  width: 100%;
  height: 0;
  background-color: #eeedf2;
  margin-top: -6px;
  transition: var(--trans);
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
}

.input_select_cnt {
  width: 100%;
  margin-top: 12px;
  height: calc(100% - 24px);
  padding: 0px 12px;
  overflow-y: scroll;
}

.input_select_item {
  padding: 8px 0px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s;
}

.province_show {
  height: 250px;
  transition: var(--trans);
}

.form_bot {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form_bot_check {
  display: flex;
  align-items: center;
}

.form_bot_check input {
  cursor: pointer;
}

.form_bot_check span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
}

.form_bot_btn {
  margin-top: 8px;
  border-radius: 6px;
}

@media only screen and (max-width: 1023px) {
  .add_address_btn {
    padding: 8px;
  }

  .form_bot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: var(--bg-white);
    margin-bottom: 0px;
    padding: 8px 12px 20px 12px;
    border-radius: 6px 6px 0px 0px;
    box-shadow: var(--box-shadow);
    align-items: flex-start;
  }
  .form_bot_btn{
    width: 100%;
  }
}