.container {
    margin: 10px 0px;
}

.head,
.head_item a {
    display: flex;
    align-items: center;
}
.head{
    margin-bottom: 8px;
}

.head_item {
    margin-right: 12px;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: var(--text-color);
    opacity: 0.6;
    transition: all .3s;
    text-transform: capitalize;
}

.head_item:hover {
    opacity: 1;
    transition: all .3s;
}

.head_item img {
    width: 18px;
    height: 18px;
}

.body {
    width: 100%;
    background-color: var(--bg-white);
    /* margin-top: 10px; */
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.body_left {
    width: 20%;
    padding: 12px 6px;
    border-right: solid 1px var(--bg-color);
}

.body_left_cate_name {
    transition: all .3s;
    padding: 6px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.body_left_cate_name:hover {
    transition: all .3s;
    background-color: var(--bg-color);
}

.body_right {
    width: 80%;
    padding: 12px 12px 12px 6px;
}

.cate_child_cnt {
    width: 100%;
    overflow-x: scroll;
    background-color: var(--bg-white);
    padding: 16px 12px;
    margin: 10px 0px;
}

.cate_child_list {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 16px;
}

.next_btn,
.prev_btn {
    position: absolute;
    right: 0;
    top: 0;
    margin: 25px -10px 0px 0px;
    background-color: var(--bg-white);
    width: 24px;
    height: 24px;
    padding: 0px;
    border-radius: 100%;
    box-shadow: var(--box-shadow);
    z-index: 10;
}

.prev_btn {
    position: absolute;
    top: 0;
    left: 0;
    margin: 25px 0px 0px -10px;
}

.next_btn:hover,
.prev_btn:hover {
    transform: scale(1.3);
}

.cate_child_item_cnt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cate_child_item_img {
    width: 55px;
    height: 55px;
    border-radius: 20px;
    background-color: var(--bg-color);
    position: relative;
    overflow: hidden;
    border: solid 1px #FAFAFA;
}

.cate_child_item_img img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    object-fit: cover;
    transition: var(--trans);
}

.cate_child_item_cnt:hover .cate_child_item_img img {
    transform: scale(1.2);
    transition: var(--trans);
}

.cate_child_item_title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-top: 10px;
    text-transform: capitalize;
}

.cate_child_item_load {
    height: 83px;
}

@media only screen and (max-width: 1023px) {
    .container {
        margin-top: 44px;
        padding: 12px;
    }

    .cate_child_cnt {
        padding: 0px;
    }

    .head_item {
        font-size: 12px;
        margin-right: 6px;
    }

    .filter_mobile_cnt {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filter_mobile_location {
        width: calc(66.66% - 6px);
    }

    .filter_mobile_other {
        width: calc(33.33% - 6px);
    }

    .filter_mobile_other_open {
        padding: 9px;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        background-color: var(--grey_2);
        color: var(--text-black);
    }

    .filter_mobile_other_open img {
        margin: 0px;
        margin-left: 6px;
    }

    .cate_child_list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 12px;
    }

    .cate_child_item_cnt {
        border: solid 1px var(--bg-color);
        padding: 4px;
        border-radius: 6px;
    }

    .cate_child_item_title {
        margin-top: 6px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .body_left {
        display: none;
    }

    .body_right {
        width: 100%;
        padding: 0px;
    }

    .body_right_list {
        grid-template-columns: repeat(2, 1fr);
    }
}