.tip{
    margin: 14px 0 0 0;
}
.tip__wrap{
    border-radius: 8px;
    padding: 14px;
    background-image: url(../../../../assets/image/bg-tip.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 128px;
}   
.tip__text{
    font-size: 14px;
    color: white;
    font-style: italic;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display:-webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
.tip__loading{
    width: 100%;
    border-radius: 8px;
}
.tip__img{
    margin-bottom: 4px;
}
.tip__top{
    display: flex;
    gap: 4px;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

@media screen and (max-width: 767.98px) {
    .tip__wrap{
        height: 106px;
    }
    .tip__text{
        -webkit-line-clamp:2;
    }
}

