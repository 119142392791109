.login-re-cnt{
    padding: 0px 20px;
    height: 100vh;
    justify-content: center;
}
.login-re-cnt .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: var(--purple);
    margin: 24px 0px 4px 0px;
}
.login-re-cnt .content{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
}
.login-re-cnt .content span{
    font-weight: 700;
}