.container {
  background-color: var(--white);
}

.head {
  padding: 12px;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head_left {
  display: flex;
  align-items: center;
  width: 80%;
}

.head_left_back {
  padding: 4px;
  border-radius: 100%;
  background-color: var(--white);
  margin-right: 6px;
  display: none;
}

.head_left_back img {
  transform: rotate(180deg);
}

.head_right_btn {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purple);
  border-radius: 100%;
}

.topic {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topic_img {
  width: 44px;
  height: 44px;
  background-color: var(--bg-color);
  border-radius: 100%;
  position: relative;
}

.topic_name {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--text-black);
  display: -webkit-box;
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin-left: 6px;
}

.body {
  padding: 12px;
}

.messages {
  height: calc(100vh - 148px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 0px 12px;
  padding-bottom: 60px;
}

.message {
  width: 100%;
  padding: 10px 0px;
}

.message_default{

}
.message_info_MC{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  margin-top: 24px;
  margin-bottom: 36vh;
}
.message_info_name{
  font-weight: bold;
}

.message_head {
  display: flex;
  align-items: center;
}

.avatar {
  width: 35px;
  height: 35px;
  background-color: var(--bg-color);
  border-radius: 100%;
  z-index: 1;
  position: relative;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  border-radius: 100%;
}

.user_name {
  font-size: 14px;
  font-weight: 600;
  margin: 0px 6px;
}

.create {
  font-size: 11px;
  font-weight: 500;
  margin-bottom: -2px;
  color: #a1a5b7;
}

.message_body {
  margin-top: 6px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.message_item_cnt {
  max-width: 75%;
  display: flex;
  flex-direction: column;
}

.message_body_cnt {
  background-color: #f8f5ff;
  padding: 12px;
  border-radius: 0px 8px 8px 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  max-width: 100%;
  word-break: break-word;
}

.message_body_cnt img {
  width: 300px;
  border-radius: 8px;
}

.message_body_images {
  width: 33vw;
  margin-top: 8px;
  grid-gap: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.message_body_images_item {
  aspect-ratio: 1 / 1;
  position: relative;
  border-radius: 8px;
  background-color: var(--bg-color);
}

.message_body_images_item img,
.message_body_images_item video {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.input_cnt {
  background-color: var(--white);
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: calc(100% - 360px);
  padding: 8px 12px;
  box-shadow: var(--box-shadow);
}
.input_cnt_top{
  width: 100%;
  overflow-x: scroll;
}
.input_cnt_top_img{
  display: flex;
}
.input_img_item_cnt{
  width: 72px;
  aspect-ratio: 1 / 1;
  background-color: var(--bg-color);
  border-radius: 8px;
  position: relative;
  margin-right: 6px;
}
.img_item{
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  position: absolute;
  border-radius: 8px;
}
.input_img_item_cnt_load{
  position: absolute;
  z-index: 2;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}
.input_img_item_cnt_load span{
  color: var(--white) !important;
}
.input_img_item_cnt button{
  position: absolute;
  z-index: 2;
  width: 24px;
  height: 24px;
  padding: 0px;
  right: 0px;
}
.input_cnt_bot{
  display: flex;
  align-items: flex-end;
}

.scroll_btn {
  position: absolute;
  z-index: 1;
  margin: 0px auto 60px auto;
  background-color: var(--white);
  width: 38px;
  height: 38px;
  padding: 0px;
  border-radius: 100%;
  margin-left: 43%;
  box-shadow: var(--box-shadow);
  visibility: hidden;
  opacity: 0;
  transition: var(--trans);
}

.scroll_btn_act {
  margin-bottom: 80px;
  visibility: visible;
  opacity: 1 !important;
  transition: var(--trans);
}

.scroll_btn img {
  transform: rotate(90deg);
}

.ip_ctl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ip_ctl_btn {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0px;
  margin-right: 6px;
}

.btn_send {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0px;
}

.text_area_cnt {
  width: calc(100% - 76px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.text_area {
  width: calc(100% - 38px);
  padding: 7px 8px;
  border: none;
  outline: none;
  background-color: #f1faff;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  max-height: 100px;
  overflow-y: scroll;
  resize: none;
}

.load {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}

.load span {
  margin-left: 10px;
}

.chatBubble {
  background-color: #f8f5ff;
  padding: 12px 14px;
  width: fit-content;
  border-radius: 8px;
  display: inline-block;
  margin-top: 4px;
}

.typing {
  align-items: center;
  display: flex;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: var(--purple);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: rgba(112, 98, 185, 0.7);
  }

  28% {
    transform: translateY(-7px);
    background-color: rgba(112, 98, 185, 0.4);
  }

  44% {
    transform: translateY(0px);
    background-color: rgba(112, 98, 185, 0.2);
  }
}

@media only screen and (max-width: 992px) and (min-width: 767px) {}

@media only screen and (max-width: 767px) {
  .head_left_back {
    display: block;
    background-color: var(--bg-color);
    width: 32px;
    height: 32px;
  }

  .messages {
    height: calc(100dvh - 122px);
  }

  @supports not (height: calc(100dvh - 122px)) {
    .messages {
      height: calc(100dvh - 150px);
      padding-bottom: 90px;
    }
  }

  .topic {
    width: calc(100% - 36px);
  }

  .topic_name {
    width: calc(100% - 44px);
  }

  .input_cnt {
    width: 100%;
  }

  .message_item_cnt {
    max-width: 100%;
  }

  .message_body_images {
    width: 79vw !important;
  }
}