.container {
    margin: 32px 0 32px 0;
    padding: 10px;
    background: #fff;
}

.org_filter_cnt {
    width: 100%;
    overflow-x: scroll;
    margin-top: 12px;
}

.tag_list {
    width: max-content;
    display: flex;
}

.tag_item,
.tag_item_load {
    padding: 4px 9px;
    border-radius: 20px;
    margin-right: 12px;
    background-color: var(--bg-white);
    border: solid 1px var(--purple);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--purple);
    transition: var(--trans);
}

.tag_item:hover {
    opacity: 0.7;
    transition: var(--trans);
}

.tag_item_load {
    border: solid 1px var(--bg-color);
    padding: 0px;
    overflow: hidden;
    width: 84px;
    height: 30px;
    background-color: var(--bg-color);
}

.org_list_cnt {
    padding: 12px 0 10px 0px;
    height: 304px;
    overflow: hidden;
}

.org_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
}

.org_list_item {
    box-shadow: var(--box-shadow);
    border-radius: 12px;
}

.org_card_item {
    border-radius: 12px 12px 0px 0px;
}

.org_img_cnt {
    position: relative;
    width: 100%;
    padding-bottom: 65%;
    background-color: var(--bg-color);
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
}

.org_avatar {
    position: absolute;
    border-radius: 12px 12px 0px 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--trans);
}

.org_avatar:hover {
    transition: var(--trans);
    transform: scale(1.1);
}

.org_detail {
    padding: 10px 6px;
}

.btn_distance {
    padding: 2px;
    color: var(--red-cl);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background-color: var(--bg-white);
}

.org_name_cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0px 6px 0px;
}

.org_de_avt {
    width: 32px;
    height: 32px;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 100%;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.org_de_avt img {
    position: absolute;
    z-index: 2;
    object-fit: cover;
    border-radius: 100%;
}

.org_de_name {
    width: calc(100% - 38px);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--text-black);
    display: -webkit-box;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.org_address {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--text-hover);
    display: -webkit-box;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

@media only screen and (max-width: 1023px) {
    .container {
        margin: 10px 0px;
        padding: 16px 12px;
    }
    .org_list_cnt {
        width: 100%;
        height: 240px;
        overflow-x: scroll;
        padding: 12px 0 0 0px;
    }

    .org_list {
        display: flex;
        grid-template-columns: none;
        width: max-content;
        grid-gap: 0px;
    }

    .org_list_item {
        width: 200px;
        margin-right: 12px;
        box-shadow: none;
    }

    .org_list_item_load {
        width: 200px;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 12px;
        height: 236px;
    }
    .load_img {
        width: 100%;
        height: 130px;
    }
    .load_detail {
        height: calc(100% - 130px);
        padding: 10px 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
