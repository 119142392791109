.re-org-item {
    cursor: pointer;
    transition: all 0.4s;
    border-radius: 8px;
    /* overflow: hidden; */
    background-color: var(--bgWhite);
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid transparent;
    position: relative;
    box-shadow: var(--box-shadow);
}

.re-org-item__enable {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}
.re-org-item__enable::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--orange);
    height: 22px;
    width: 15px;
    margin: 20px 0px 0px 4px;
    clip-path: polygon(84% 0, 99% 100%, 51% 43%, 0 100%, 17% 0);
}

.re-org-item:hover {
    box-shadow: 0 6px 15px #dcd8ee;
    transform: translateY(-10px);
}

.re-org-item .org-img-cnt {
    position: relative;
}

.re-org-item .org-img-cnt__rate {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 4px;
    left: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.org-img-cnt__rate-item {
    background-color: var(--bgWhite);
    margin: 4px;
    padding: 0px 4px;
    border-radius: 4px;
}

.org-img-cnt__rate-item img {
    width: 16px;
    height: 16px;
}

.org-img-cnt__rate-item span {
    font-size: 14px;
    font-weight: 700;
}

.re-org-item .re-org-item__img {
    width: 100%;
    height: auto;
    min-height: 159px;
    border-radius: 8px;
}

.re-org-item__cnt {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    background-color: #fff;
}

.re-org-item__cnt .org_name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: var(--text-black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.re-org-item__cnt .org_address,
.re-org-item__cnt .org_tag,
.re-org-item__cnt .org_distance {
    display: flex;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    color: var(--text-black);
}
.re-org-item__cnt .org_address span {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    color: grey;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
.re-org-item__cnt .org_address {
    margin-top: auto;
}
.re-org-item__cnt .org_address img,
.re-org-item__cnt .org_tag img {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    margin-top: 2px;
}

.re-org-item__cnt .org_distance div {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--purple);
    margin: 0px 4px 0px 4px;
}
