@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand/Quicksand-SemiBold.woff2') format('woff2'),
        url('./Quicksand/Quicksand-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand/Quicksand-Light.woff2') format('woff2'),
        url('./Quicksand/Quicksand-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand/Quicksand-Bold.woff2') format('woff2'),
        url('./Quicksand/Quicksand-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand/Quicksand-Medium.woff2') format('woff2'),
        url('./Quicksand/Quicksand-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand/Quicksand-Regular.woff2') format('woff2'),
        url('./Quicksand/Quicksand-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* NUNITO */
@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-BlackItalic.woff2") format("woff2"),
        url("./Nunito/Nunito-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-Bold.woff2") format("woff2"),
        url("./Nunito/Nunito-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-Black.woff2") format("woff2"),
        url("./Nunito/Nunito-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-ExtraBoldItalic.woff2") format("woff2"),
        url("./Nunito/Nunito-ExtraBoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-BoldItalic.woff2") format("woff2"),
        url("./Nunito/Nunito-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-ExtraBold.woff2") format("woff2"),
        url("./Nunito/Nunito-ExtraBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-ExtraLightItalic.woff2") format("woff2"),
        url("./Nunito/Nunito-ExtraLightItalic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-Italic.woff2") format("woff2"),
        url("./Nunito/Nunito-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-ExtraLight.woff2") format("woff2"),
        url("./Nunito/Nunito-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NunitoRegular";
    src: url("./Nunito/Nunito-Regular.woff2") format("woff2"),
        url("./Nunito/Nunito-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-Light.woff2") format("woff2"),
        url("./Nunito/Nunito-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-LightItalic.woff2") format("woff2"),
        url("./Nunito/Nunito-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-SemiBold.woff2") format("woff2"),
        url("./Nunito/Nunito-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Nunito";
    src: url("./Nunito/Nunito-SemiBoldItalic.woff2") format("woff2"),
        url("./Nunito/Nunito-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/* Inter */
@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-ExtraBold.woff2") format("woff2"),
        url("./Inter/Inter-ExtraBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-Bold.woff2") format("woff2"),
        url("./Inter/Inter-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-Black.woff2") format("woff2"),
        url("./Inter/Inter-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-ExtraLight.woff2") format("woff2"),
        url("./Inter/Inter-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-Light.woff2") format("woff2"),
        url("./Inter/Inter-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-Medium.woff2") format("woff2"),
        url("./Inter/Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-Regular.woff2") format("woff2"),
        url("./Inter/Inter-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-SemiBold.woff2") format("woff2"),
        url("./Inter/Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/Inter-Thin.woff2") format("woff2"),
        url("./Inter/Inter-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
