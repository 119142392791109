.container {
    width: 100%;
}

.title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: var(--black)
}

.container_user {
    margin-top: 10px;
}

.user_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.user_row_detail {
    display: flex;
    width: calc(100% - 120px);
    justify-content: space-between;
}

.user_row_label,
.user_row_detail_text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.user_row_detail_text {
    font-weight: 700;
    max-width: 224px;
}

.user_edit_btn {
    padding: 6px !important;
    margin-top: -4px !important;
    width: 26px !important;
    height: 26px !important;
}

@media only screen and (max-width: 1023px) {
    .user_row{
        margin-bottom: 4px;
    }
    .user_row_label,
    .user_row_detail_text {
        font-size: 13px;
    }

    .user_row_detail_text {
        width: calc(100% - 32px);
    }

    .user_row_detail {
        width: calc(100% - 25%);
    }

    .user_edit_btn {
        width: 28px;
        height: 28px;
        margin: 0px;
    }
}