.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0px;
    min-height: calc(100vh - 96px);
    height: max-content;
}

.title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}

.container_qr {
    width: calc(65% - 6px);
    height: 100%;
    overflow: scroll;
    padding: 12px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    background-color: var(--bg-white);
}

.payment_left_body_guide {
    margin-top: 8px;
}

.guide_item {
    display: flex;
    font-size: 14px;
    line-height: 18px;
}

.guide_item h4 {
    margin: 0px 2px;
}

.payment_status_icon {
    display: none;
}

.payment_left_body_status {
    margin-top: 8px;
}

.payment_status_decs_top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.payment_left_open_payment {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.open_payment_btn {
    padding: 10px 18px !important;
    background-color: var(--pr-green) !important;
    border-radius: 6px !important;
}
.payment_left_other{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment_left_other_title{
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}
.payment_left_other_qr{
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qr_img{
    width: 240px;
    height: 240px;
    object-fit: cover;
}
.qr_img_download_btn{
    display: flex;
    width: max-content;
    background-color: var(--green);
    padding: 4px;
    border-radius: 4px;
    margin-bottom: 2px;
}
.qr_img_download_btn img{
    transform: scale(0.7);
}
.bank_name{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}
.bank_account_number{
    margin-top: -8px;
    display: flex;
    align-items: flex-end;
    font-size: 16px;
    font-weight: 600;
}
.copy_info_btn{
    margin-left: 6px;
    padding: 0px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
}
.payment_left_other_desc{
    margin-top: 12px;
}
.other_desc_row{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
}
.other_desc_row label{
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
}
.other_desc_row span{
    font-size: 16px;
    font-weight: 700;
}
.payment_left_other_guide{
    margin-top: 12px;
    background-color: #FEE3D2;
    padding: 6px 8px;
    border-radius: 6px;
}
.payment_left_other_guide p{
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}




.payment_status_decs_top_title,
.payment_status_decs_top_box {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-right: 8px;
}

.payment_status_decs_top_box {
    margin: 0px;
    padding: 3px 8px;
    border-radius: 4px;
    color: var(--bg-white);
}

.payment_status_decs_bot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4px;
}

.payment_status_decs_bot span {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    margin-right: 8px;
}

.payment_status_decs_bot h3 {
    color: var(--pink-momo);
}

.payment_left_qr_cnt {
    width: 100%;
    margin-top: 8px;
    height: calc(100vh - 250px);
    overflow-y: scroll;
}

.payment_left_qr {
    width: 100%;
    height: calc(100% + 670px);
    margin-top: -670px;
    /* height: calc(100% + 0px); */
    /* margin-top: -0px; */
    border: none;
    outline: none;
}

.container_right {
    height: max-content;
    width: calc(35% - 6px);
    padding: 12px;
    background-color: var(--bgWhite);
    box-shadow: var(--box-shadow);
    border-radius: 8px;
}

/* payment info */
.section_org {
    padding: 12px 0px;
    border-top: solid 1px var(--bg-color);
    display: flex;
    justify-content: space-between;
}

.org_img {
    width: 54px;
    height: 54px;
    background-color: var(--bg-color);
    border-radius: 6px;
    position: relative;
    border: solid 1px var(--bg-color);
}

.org_img img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 1;
    object-fit: cover;
}

.org_right {
    width: calc(100% - 60px);
}

.org_name,
.org_address {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.org_address {
    font-weight: 500;
}

.order_list {
    padding: 12px 0px;
    border-top: solid 1px var(--bg-color);
}

.order_item {
    display: flex;
    justify-content: space-between;
    padding: 6px 0px;
}

.order_item_price {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price span:first-child {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}

.price span:nth-child(2),
.quantity {
    font-size: 14px;
    line-height: 16px;
    margin-left: 6px;
    font-weight: 500;
    text-decoration: line-through;
}

.quantity {
    text-decoration: none;

}

.section_guide {
    margin-top: 12px;
    position: relative;
    padding: 12px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    background-color: #ffe3d2;
}

.section_guide span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: var(--text-orange);
}

.section_bottom {
    margin-top: 18px;
}

.bottom_bill_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.row_label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: var(--text-hover);
}

.row_value {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
}

.bottom_pay,
.bottom_pay_left {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom_pay_left {
    margin: 0px;
    width: 100%;
}

.bottom_pay_left_label,
.bottom_pay_left_value {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.bottom_pay_left_value {
    color: var(--text-orange);
    font-weight: 600;
}

.section_status {
    margin-top: 18px;
}

.section_status_btn {
    width: 100%;
    border-radius: 6px;
}

.section_status_title {
    text-align: center;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: var(--red_2);
}

.section_status_btn_cnt {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
    .container {
        padding-top: 56px;
        flex-direction: column;
        justify-content: unset;
        padding-bottom: 150px;
    }

    .container_qr {
        width: 100%;
    }

    .payment_left_body>span {
        display: none;
    }

    .payment_left_qr_cnt,
    .payment_left_body_guide {
        display: none;
    }

    .open_payment_btn {
        background-color: var(--purple);
        position: fixed;
        bottom: 20px;
        right: 12px;
        z-index: 62;
        padding: 8px 12px !important;
    }

    .payment_left_body_status {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .payment_status_icon {
        display: block;
        position: relative;
        width: 140px;
        height: 140px;
    }

    .payment_status_decs {
        width: 100%
    }

    .payment_status_decs_top {
        justify-content: space-between;
    }

    .container_right {
        width: 100%;
        margin-top: 12px;
    }

    .section_bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 61;
        border-radius: 6px 6px 0px 0px;
        padding: 8px 12px 20px 12px;
        box-shadow: var(--box-shadow);
        background-color: var(--bg-white);
    }

    .bottom_pay {
        align-items: flex-end;
        margin-top: 6px;
        padding-top: 6px;
        border-top: solid 1px var(--bg-color);
    }

    .bottom_pay_left {
        width: calc(100% - 120px);
    }

    .bottom_pay_left_value {
        font-size: 20px;
    }

    .bottom_pay_right_btn {
        padding: 8px 12px !important;
        background-color: var(--pr-green) !important;
    }

    .bottom_pay_left_label,
    .bottom_pay_left_value {
        line-height: 24px;
    }

    .section_status_modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #00000099;
        z-index: 101;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section_status {
        background-color: var(--bg-white);
        padding: 12px;
        width: calc(100% - 24px);
        border-radius: 6px;
    }
}