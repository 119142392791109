.container {
    width: 100%;
    height: 350px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.banner_container {
    width: calc(70% - 12px);
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.banner_item_cnt {
    position: relative;
    width: 100%;
    height: 348px;
    background-color: var(--bg-color);
    border-radius: 10px;
    overflow: hidden;
}

.banner_img {
    position: absolute;
    border-radius: 10px;
    z-index: 10;
    width: 100%;
    transform: translateY(-16%);
    object-fit: cover;
}

.right_container_wrapper {
    width: calc(30% - 12px);
    height: 100%;
}

.right_container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
}

.banner_container ul {
    margin-top: -60px;
}

.slide_btn>img {
    width: 36px;
    height: 36px;
    object-fit: cover;
}

.slide_btn {
    position: absolute;
    z-index: 10;
    background-color: var(--bg-white);
    padding: 0px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}

.slide_btn_left {
    left: 10px;
}

.slide_btn_right {
    right: 10px;
}

.slide_btn_left>img {
    transform: rotate(180deg);
}

.feature_item_cnt {
    cursor: pointer;
    background-color: var(--bg-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: var(--trans);
}

.feature_item_cnt:hover {
    box-shadow: var(--box-shadow);
    transition: var(--trans);
}

.feature_item_icon {
    width: 36px;
    height: 36px;
    position: relative;
}
.feature_item_dot{
    position: absolute;
    z-index: 1;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--red_2);
    top: -1px;
    right: -1px;
}

.feature_item_icon img {
    width: 100%;
    height: 100%;
}

.feature_item_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 14px;
    text-align: center;
}

.popup_container {
    width: 80vw;
    height: 75vh;
    background-color: var(--bg-white);
}

.video_container {
    width: 100%;
    height: 100%;
    padding: 10px;
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
        display: flex;
        height: max-content;
        flex-direction: column;
    }

    .slide_btn>img {
        width: 24px;
        height: 24px;
    }

    .banner_container {
        width: 100%;
        height: 330px;
    }

    .banner_img {
        border-radius: 0px;
        object-fit: cover;
        transform: translateY(-22%);
    }

    .banner_item_cnt {
        height: 340px;
        border-radius: 0px;
    }

    .banner_mb_cnt {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 200px;
        top: 0;
        display: flex;
        align-items: center;
        backdrop-filter: blur(10px);
    }

    .banner_mb_cnt_img {
        width: 100vw;
        margin-top: 10px;
    }

    .right_container_wrapper {
        position: absolute;
        height: max-content;
        padding: 0px 16px;
        z-index: 2;
        width: 100%;
        bottom: -44px;
    }

    .right_container {
        display: flex;
        grid-gap: 0px;
        align-items: center;
        background-color: var(--bg-white);
        height: 82px;
        box-shadow: var(--box-shadow);
        border-radius: 16px;
    }

    .feature_item_cnt {
        box-shadow: none !important;
        background-color: transparent;
        width: 25%;
    }

    .feature_item_icon {
        border-radius: 12px;
        background-color: var(--bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .feature_item_icon img {
        width: 20px;
        height: 20px;
    }

    .feature_item_title {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .container {
        display: flex;
        height: max-content;
        flex-direction: column;
    }

    .slide_btn>img {
        width: 24px;
        height: 24px;
    }

    .banner_container {
        width: 100%;
        /* height: 256px; */
        height: 230px;
    }

    .banner_img {
        border-radius: 0px;
        /* height: 240px; */
        object-fit: cover;
        transform: translateY(-9%);
    }

    .banner_item_cnt {
        height: 240px;
        border-radius: 0px;
    }

    .banner_mb_cnt {
        position: absolute;
        z-index: 2;
        width: 100%;
        /* height: 256px; */
        height: 200px;
        top: 0;
        display: flex;
        align-items: center;
        backdrop-filter: blur(10px);
    }

    .banner_mb_cnt_img {
        width: 100vw;
        margin-top: 10px;
    }

    .right_container_wrapper {
        position: absolute;
        height: max-content;
        padding: 0px 16px;
        z-index: 2;
        width: 100%;
        bottom: -44px;
    }

    .right_container {
        display: flex;
        grid-gap: 0px;
        align-items: center;
        background-color: var(--bg-white);
        height: 82px;
        box-shadow: var(--box-shadow);
        border-radius: 16px;
    }

    .feature_item_cnt {
        box-shadow: none !important;
        background-color: transparent;
        width: 25%;
    }

    .feature_item_icon {
        border-radius: 12px;
        background-color: var(--bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .feature_item_icon img {
        width: 20px;
        height: 20px;
    }

    .feature_item_title {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        margin-top: 10px;
    }
}