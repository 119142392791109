.form_container {
    width: 80%;
}

.form_row {
    width: calc(100% - 150px);
    padding: 0px 16px;
}

@media only screen and (max-width: 1023px) {
    .form_row {
        width: 100%;
    }
}