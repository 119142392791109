.container {
    margin: 24px 0px 66px 0px;
    padding: 10px;
    background-color: var(--bg-white);
}

.list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
}

.list_item_title {
    margin: 16px 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.list_item_desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
@media screen and (max-width: 767.98px) {
    .container {
        margin: 16px 0;
        padding: 16px 0;
        overflow: hidden;
    }
    .slick-initialized .slick-slide {
        margin: 0 16px;
    }
    .list_item_content {
        padding: 8px;
        margin: 0 8px;
        background-color: #fafafa;
        border-radius: 10px;
        text-align: center;
        border: 1px solid rgba(204, 204, 204, 0.075);
    }

    .list_item_title {
        margin: 8px 0 0 0;
        font-size: 14px;
    }
    .list_item_desc {
        font-size: 12px;
    }
    .list_item_content > img {
        margin: 0 auto;
    }
    .list_item {
        width: max-content;
    }
    .whynot_title {
        padding: 0 12px;
    }
    .list {
        margin-top: 16px;
        display: block;
    }
}
