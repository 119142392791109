.favorite_list {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.favorite_list_item {
    height: 116px;
    padding: 8px;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
}

.favorite_item_cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.favorite_org_img {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 8px;
    background-color: var(--bg-color);
}

.favorite_org_img img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 8px;
}

.favorite_org_de {
    width: calc(100% - 108px);
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
}

.org_name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.org_view {
    width: 380px;
    min-height: 180px;
    height: max-content;
    background-color: var(--bg-white);
    border-radius: 12px;
    box-shadow: var(--box-shadow);
    position: absolute;
    display: none;
    z-index: 102;
}

.org_view_cnt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.org_view_show {
    display: block;
    margin-top: 200px;
}

.org_view_show_above {
    display: block;
    margin-top: -220px;
}

.org_view_cnt_head {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.view_left_img {
    width: 76px;
    height: 76px;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 100%;
    border: solid 1px var(--bg-color);
}

.view_left_img img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    border-radius: 100%;
}

.view_right {
    width: calc(100% - 80px);
}

.view_org_name {
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
}

.view_org_address {
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    margin: 6px 0px;
}

.view_org_f_count {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.view_right_icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.org_view_cnt_bot {
    padding: 0px 12px 8px 12px;
    display: flex;
    justify-content: space-between;
}

.org_view_cnt_bot button {
    width: calc(50% - 6px);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--bg-white);
    color: var(--purple);
    border: solid 1px var(--purple);
}

.org_view_cnt_bot button:first-child {
    background-color: var(--red-cl);
    color: var(--bg-white);
    border: solid 1px var(--red-cl);
}

.load_item {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.load_item_img {
    width: 100px;
    height: 100%;
    border-radius: 8px;
}

.load_item_img_right {
    width: calc(100% - 108px);
    height: 100%;
}

.favorite_list_btn {
    margin-top: 12px;
    display: flex;
    justify-content: center;
}

.favorite_list_btn button {
    background-color: transparent;
    color: var(--purple);
}

@media only screen and (max-width: 1023px) {
    .favorite_list {
        grid-gap: 12px;
        grid-template-columns: repeat(1, 1fr);
    }

    .favorite_list_btn {
        padding-bottom: 60px;
    }
}