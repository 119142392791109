.home_cate_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
    .container {
        padding-bottom: 100px;
    }
}