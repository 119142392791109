.story {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 135px 0 120px 0;
}
.container {
    display: flex;
    max-width: 1238px;
    margin: 0 auto;
    padding: 0 15px;
    gap: 14px;
}
.title {
    color: #000;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 54px */
}
.story_left p {
    color: #000;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    max-width: 521px;
}
.story_left span {
    font-weight: bold;
}
.story_left {
    display: flex;
    flex-direction: column;
    gap: 47px;
}

.story_right {
    width: 630px;
    flex-shrink: 0;
    transform: translateY(-24px);
}
