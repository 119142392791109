.container {
  margin-top: 32px;
  padding: 10px;
  background-color: var(--bg-white);
}

.list_cnt>div>ul {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

.list_cnt>div>ul>li {
  height: 250px;
}

.list_cnt>div>ul>li>div:nth-child(1) {
  aspect-ratio: 2 / 1;
  height: unset;
}

.list_cnt>div>ul>li>div:nth-child(2) {
  height: 86px;
  overflow: hidden;
}

.list {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  grid-row-gap: 32px;
}

.item_img {
  position: relative;
  aspect-ratio: 2 / 1;
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
}

.item_img img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all .4s;
}

.item_img:hover img {
  transform: scale(1.1);
  transition: all .4s;
}

.item_cnt {
  padding: 6px 0px;
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name span {
  width: calc(100% - 44px);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--text-black);
  display: -webkit-box;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.address {
  margin-top: 4px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--text-hover);
  display: -webkit-box;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@media only screen and (max-width: 739px) {
  .list_cnt {
    width: 100%;
    overflow-x: scroll;
  }

  .list,
  .list_cnt>div>ul {
    margin-top: 12px;
    display: flex;
    width: max-content;
  }

  .item_cnt,
  .list_cnt>div>ul>li {
    width: 200px;
    margin-right: 12px;
  }

  .list_cnt>div>ul>li {
    height: 194px;
    overflow: hidden
  }
}