.container {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
    padding: 12px;
    background-color: var(--bg-white);
    border-radius: 6px;
}

.left,
.right {
    width: calc(65% - 6px);
    padding: 12px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
}

.left {
    box-shadow: none;
    padding: 0px;
}

.left_head,
.left_head_ctl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
    font-size: 13px;
    font-weight: 600;
}

.left_head {
    box-shadow: var(--box-shadow);
    padding: 6px;
    border-radius: 6px;
    position: sticky;
    top: 12px;
    background-color: var(--bg-white);
    z-index: 10;
}

.left_head_label {
    width: 350px;
}

.left_head_ctl {
    width: calc(100% - 350px);
}

.left_head_ctl_item {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 13px;
    font-weight: 600;
}

.left_head_ctl_item_btn {
    margin-left: 6px;
    padding: 5px;
    background-color: var(--red_2);
}

.group_org_item {
    padding: 6px 12px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    margin-top: 12px;
}

.org_detail_left {
    display: flex;
    align-items: center;
}

.group_org_detail_name {
    display: flex;
    align-items: center;
}
.org_link_cnt{
    display: flex;
    align-items: center;
    transition: var(--trans);
}
.org_link_cnt:hover{
    opacity: 0.7;
    transition: var(--trans);
}
.org_img {
    width: 36px;
    height: 36px;
    position: relative;
    background-color: var(--bg-color);
    border-radius: 6px;
    border: solid 1px var(--bg-color);
}

.org_img img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}

.org_name {
    margin-left: 6px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: var(--purple);
    cursor: pointer;
}

.org_branch_btn {
    padding: 0px 4px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 700;
    margin-left: 12px;
}

.group_org_detail_address {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
}

.cart_item {
    width: 100%;
    border-bottom: solid 1px var(--bg-color);
    margin-top: 8px;
    padding: 6px 4px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.discount_badge {
    position: absolute;
    z-index: 2;
    background-color: var(--text-orange);
    padding: 4px 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    top: -4px;
    left: -4px;
    opacity: 0.4;
    transition: var(--trans);
}

.discount_badge span {
    font-size: 13px;
    line-height: 10px;
    font-weight: 600;
    color: var(--bg-white);
}

.cart_item_left {
    height: 100%;
    width: 340px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.cart_item_left_check {
    display: flex;
    align-items: center;
    width: 100%;
}
.link_cart_item_detail{
    display: flex;
    width: calc(100% - 38px);
}

.item_img {
    width: 74px;
    height: 74px;
    position: relative;
    border-radius: 6px;
    background-color: var(--bg-color);
}

.item_img img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.item_name {
    width: calc(100% - 110px);
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    margin-left: 6px;
}

.cart_item_right {
    width: calc(100% - 340px);
    height: 74px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cart_item_right_quantity,
.cart_item_right_price_total {
    display: flex;
    align-items: center;
}

.cart_item_right_quantity button {
    font-size: 26px;
    padding: 0px;
    font-weight: 500;
    width: 30px;
    height: 30px;
    border-radius: 6px;
}

.cart_item_right_quantity button:first-child {
    background-color: var(--bg-color);
    color: var(--text-black);
}

.item_quantity {
    width: 26px;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
}

.cart_item_right_price,
.cart_item_right_price_total {
    line-height: 30px;
    font-size: 13px;
    font-weight: 600;
    color: var(--purple);
}

.cart_item_right_price_total {
    font-size: 15px;
    color: var(--text-orange);
}

.right {
    width: calc(35% - 6px);
    box-shadow: none;
    padding: 0px;
}

.right_section {
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
}

.right_section_title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: var(--black);
}

.bottom {
    position: sticky;
    top: 12px;
    background-color: var(--bg-white);
    z-index: 61;
}

.checkout_out {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
}

.checkout_out_amount {
    display: flex;
    justify-content: space-between;
}

.checkout_out_amount_label {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
}

.checkout_out_amount_price {
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
}

.checkout_out_amount_btn {
    margin-top: 8px;
    border-radius: 6px;
    background-color: var(--pr-green);
}

.open_voucher_bnt {
    background-color: var(--bg-white);
    width: 100%;
    color: var(--text-orange);
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 0px 0px 6px 0px;
    border-bottom: solid 1px var(--bg-color);
    border-radius: 0px;
}

.open_voucher_bnt img {
    margin: 0px 0px -2px 4px;
}
.calc_pont{
    padding: 4px 0px;
    border-bottom: solid 1px var(--bg-color);
}
.calc_body {
    padding: 6px 0px;
    border-bottom: solid 1px var(--bg-color);
}

.calc_body_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.calc_body_row_price {
    font-weight: 500;
    font-size: 16px;
}

/* branch */
.branch_cnt {
    position: relative;
}

.branch {
    position: absolute;
    width: 540px;
    height: max-content;
    max-height: 350px;
    overflow-y: scroll;
    background-color: var(--bg-white);
    z-index: 11;
    box-shadow: var(--box-shadow);
    padding: 12px;
    border-radius: 6px;
    margin-top: 18px;
    visibility: hidden;
    opacity: 0;
    transition: var(--trans);
}

.branch_show {
    visibility: visible;
    opacity: 1;
    margin-top: 8px;
    transition: var(--trans);
}

.branch_item {
    padding: 4px 0px;
    border-bottom: solid 1px var(--bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.branch_check {
    align-items: center;
    border: 2px solid var(--black);
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    margin-right: 6px;
    width: 20px;
}

.branch_check span {
    background-color: var(--purple);
    border-radius: 100px;
    height: 11px;
    width: 11px;
}

.branch_item_de {
    width: calc(100% - 26px);
}

.branch_name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.branch_name span {
    margin-right: 4px;
    font-weight: 600;
}

@media only screen and (max-width: 1023px) {
    .left_head_ctl_item_btn {
        flex-direction: row-reverse;
        width: max-content;
        font-size: 12px;
        font-weight: 600;
        color: var(--text-orange);
        background-color: var(--bg-white);
        padding: 0px;
        margin: 0px;
    }

    .container {
        flex-direction: column-reverse;
        margin: 44px 0px;
        padding: 0px;
        box-shadow: none;
    }

    .right {
        width: 100%;
        box-shadow: none;
    }

    .left_head {
        display: none;
    }

    .left {
        width: 100%;
        padding-bottom: 300px;
    }

    .group_org_detail_name {
        justify-content: space-between;
    }

    .org_name {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-left: -6px;
        color: var(--text-black);
    }

    .org_branch_btn {
        /* color: var(--purple); */
        /* background-color: transparent; */
        margin: 0px;
        font-size: 12px;
    }

    .group_org_detail_address {
        font-size: 12px;
        margin-top: 4px 0px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .cart_item {
        flex-direction: column;
    }

    .cart_item_left {
        width: 100%;
    }

    .cart_item_right {
        width: 100%;
        position: absolute;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-left: 110px;
        align-items: flex-end;
    }

    .remove_item_btn,
    .org_img,
    .cart_item_right_price {
        display: none;
    }

    .bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        top: unset;
        width: 100%;
        z-index: 61;
        background-color: var(--bg-white);
        margin-bottom: 0px;
        padding: 8px 12px 20px 12px;
        border-radius: 6px 6px 0px 0px;
        box-shadow: var(--box-shadow);
    }

    .checkout_out {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .checkout_out_amount {
        width: calc(100% - 118px);
        font-weight: 500;
        align-items: flex-start;
    }

    .calc_body_row_label {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .checkout_out_amount_label {
        font-size: 16px;
        line-height: 24px;
    }

    .checkout_out_amount_price {
        font-size: 20px;
        color: var(--text-orange);
    }

    .checkout_out_amount_btn {
        width: 111px;
        padding: 8px 0px;
        margin: 0px;
    }

    .branch {
        position: fixed;
        bottom: 0;
        left: 0;
        visibility: visible;
        opacity: 1;
        width: 100vw;
        z-index: 110;
        margin-bottom: -100%;
        border-radius: 12px 12px 0px 0px;
        box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.22);
    }

    .branch_show {
        margin-bottom: 0px;
    }
}