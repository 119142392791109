.container {
    padding: 12px;
    margin: 24px 0px;
    background-color: var(--bg-white);
    border-radius: 6px;
}

.discount_list {
    grid-gap: 12px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
        margin: 44px 0px 0px 0px;
    }

    .discount_list {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 769px) {
    .container {
        margin: 44px 0px 0px 0px;
    }

    .discount_list {
        grid-template-columns: repeat(2, 1fr);
    }
}