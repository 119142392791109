.container {
  width: 574px;
  padding: 12px;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head button {
  padding: 8px;
  background-color: var(--white);
  border-radius: 100%;
}

.head button:hover {
  background-color: var(--bg-color);
}

.head span {
  font-size: 16px;
  font-weight: 600;
}

.head div {
  width: 36px;
  height: 36px;
}

.org {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.org_image {
  aspect-ratio: 1 / 1;
  width: 64px;
  border-radius: 8px;
  background-color: var(--bg-color);
  position: relative;
}

.org_image img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.org h5 {
  width: calc(100% - 76px);
  font-weight: 600;
}
.body{
  margin-top: 12px;
}
.list {
  grid-gap: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.list_item button,
.copy_btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 8px !important;
  border-radius: 8px !important;
  border: solid 1px var(--bg-color) !important;
  transition: var(--trans);
  background-color: var(--white);
}
.list_item button:hover{
  background-color: var(--bg-color) !important;
  transition: var(--trans);
}
.list_item button span{
  margin-left: 12px;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black);
}
.copy_icon{
  width: 33px;
  height: 33px;
  background-color: var(--purple);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 992px) and (min-width: 767px) {}

@media only screen and (max-width: 767px) {
  .container{
    width: calc(100vw - 24px);
  }
}