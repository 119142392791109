.container {
    width: 100%;
    height: 100%;
}

.card {
    width: 100vh;
    height: 100vw;
    background-color: black;
    transform: rotate(90deg);
    transform-origin: left;
    margin-left: 50vw;
    margin-top: -50vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_cnt {
    max-width: 550px;
    max-height: 370px;
    width: 70%;
    height: calc(100% - 12px);
    transition: .8s;
    position: relative;
}

.card_cnt_font,
.card_cnt_back {
    background-image: url('assets/image/userCardBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    padding: 18px 18px 3px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    transition: all .8s;
}
.rotate_btn{
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    padding: 12px;
    background-color: transparent;
}

.card_cnt_back {
    background-color: antiquewhite;
    transform: rotateY(-180deg);
}

.card_font_tr {
    transform: rotateY(180deg);
}

.card_back_tr {
    transform: rotateY(0deg);
}


.card_head {
    display: flex;
    align-items: center;
}

.card_head_user {
    margin-left: 18px;
    color: var(--bg-white);
}

.card_head_img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    background-color: var(--bg-color);
}

.card_head_img img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.user_name {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
}

.user_email {
    display: flex;
    align-items: center;
}

.user_email img {
    margin-right: 6px;
}

.card_bot_de {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.card_bot_qr {
    width: 120px;
    height: 120px;
    background-color: var(--bg-white);
    border-radius: 6px;
}

.card_bot_right {
    padding: 6px 8px;
    background-color: var(--bg-white);
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    border-radius: 26px;
}

.card_bot_right img {
    width: 34px;
    height: 34px;
    margin-right: 8px;
}

.card_bot_right_count {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.card_bot_right_count p:nth-child(2) {
    font-weight: 600;
    color: var(--text-orange);
}

.card_bot_qr_label {
    margin-top: 3px;
    font-size: 14px;
    line-height: 16px;
    color: var(--bg-white);
}

.card_back_rank {
    color: var(--bg-white);
}

.card_back_rank_label {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.card_back_rank_label img {
    margin-right: 6px;
}

.card_back_rank_point {
    margin: 4px 0px 0px 22px;
    font-size: 20px;
    line-height: 22px;
}

.card_back_rank_track {
    margin-top: 12px;
    width: 100%;
    height: 46px;
    /* background-color: var(--bg-white); */
    position: relative;
}

.track_cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 3;
}

.track_item {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    padding: 8px 6px;
    border-radius: 6px;
    border-radius: 14px;
    opacity: 0.7;
}

.track_line {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 7px;
    background-color: var(--bg-color);
    margin-top: 13px;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.track_line_par {
    position: absolute;
    width: 100%;
    height: 7px;
}

.track_line_child {
    background-color: var(--red-cl);
    border-radius: 0px 3.5px 3.5px 0px;
    height: 7px;
}

.back_rank_detail {
    width: 100%;
    height: calc(100% - 110px);
}

.back_rank_detail_label {
    color: var(--bg-white);
}

.back_rank_detail_body {
    background-color: var(--bg-white);
}

.back_rank_detail_body {
    background-color: var(--bg-white);
    margin-top: 6px;
    padding: 8px;
    border-radius: 12px;
    width: 100%;
    height: calc(100% - 32px);
}