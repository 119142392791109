.my-ser {
    padding: 0px 0px 64px 0px;
    width: 100%;
    min-height: calc(100vh - 60px);
    height: max-content;
    align-items: flex-start;
}

.my-ser__left {
    padding: 20px;
    width: 25%;
    height: max-content;
    background-color: var(--bgWhite);
    border-radius: 24px;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
}

.my-ser__right {
    width: 100%;
    height: max-content;
}

.my-ser-submit__btn {
    margin-top: 16px;
}

.my-ser__left-title img:last-child {
    margin-left: auto;
}

.my-ser__left-title img:first-child {
    margin-right: 4px;
}

.my-ser__left-title {
    margin-bottom: 16px;
}

.my-ser__left-title,
.my-ser-book__cnt-head-title {
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    color: var(--purple);
}

/* org select */
.my-ser__org {
    padding-left: 28px;
}

.my-ser__org-head {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--purple);
    padding: 6px 0px;
    border-bottom: solid 1px var(--purple);
}

.my-ser__org-item {
    margin: 6px 0px 6px 4px;
    padding: 4px 0 4px 4px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text-hover);
    transition: all 0.3s;
}

.my-ser__org-item:hover {
    margin-left: 4px;
}

/* service book */
.my-ser__right {
    padding-left: 24px;
}

.my-ser-book__cnt {
    padding: 20px;
    padding-top: 0px;
}

.my-ser-book__cnt-head {
    padding-bottom: 12px;
    border-bottom: solid 1px var(--purple);
}

.my-ser-book__cnt-head-btn {
    margin-top: 14px;
}

.my-ser-book__cnt-head-btn span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--purple);
}

.my-ser-book__cnt-head-btn button {
    outline: none;
    background-color: transparent;
    border: solid 1px var(--purple);
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--purple);
    border-radius: 16px;
    margin: 0px 8px;
    transition: all 0.3s;
}

/* service item */
.my-ser-book__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.my-ser-book__list li {
    width: 50%;
    background-color: var(--bgWhite);
    padding: 12px 8px;
}

.treat-card-item {
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 12px;
    background-color: var(--bgWhite);
    position: relative;
    transition: var(--trans);
}

.treat-card-item__dot {
    width: 10px;
    height: 10px;
    background-color: var(--green);
    position: absolute;
    top: 0;
    right: 0;
    margin: -4px -4px 0px 0px;
    border-radius: 100%;
}

.treat-card-item__book {
    position: absolute;
    z-index: 1;
    background-color: var(--orange);
    color: var(--white);
    top: 0;
    right: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    padding: 0px 8px;
    border-radius: 6px;
    margin: -14px -12px 0px 0px;
    cursor: pointer;
    transition: all 0.3s;
    height: 0px;
    overflow: hidden;
}

.treat-card-item__book:hover {
    opacity: 0.6;
}

.treat-card-item__book img {
    margin-left: 4px;
}

.treat-card-item__head {
    display: flex;
    justify-content: space-between;
}

.treat-card-item__head .org-name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: var(--purple);
}

.treat-card-item__head .time,
.treat-card-item__head .code {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.treat-card-item__head .time span,
.treat-card-item__head .code span {
    font-weight: 700;
    margin-left: 4px;
}

.treat-card-item__ser-count .title {
    font-size: 14px;
    font-weight: 700;
}

.treatment-ser-item {
    display: flex;
    flex-direction: row;
    padding: 8px 0px;
    border-bottom: 1px solid var(--bg-color);
    cursor: pointer;
}

.treatment-ser-item__out {
    background-color: #ddffe9;
    padding: 2px 4px;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #13b139;
    border-radius: 4px;
    cursor: pointer;
}

.treatment-ser-item__img {
    width: 86px;
    height: 86px;
}

.treatment-ser-item__img img {
    border-radius: 8px;
    object-fit: cover;
}

.treatment-ser-item__cnt {
    padding-left: 6px;
    width: calc(100% - 128px);
    display: flex;
    flex-direction: column;
}

.treatment-ser-item__cnt .ser-name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: var(--purple);
}

.treatment-ser-item__cnt .ser-desc {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--text-hover);
    margin: 4px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.treatment-ser-item .quantity {
    padding: 4px 8px;
    background-color: var(--grey);
    width: fit-content;
    border-radius: 8px;
}

.treatment-ser-item .quantity .quantity-text {
    display: flex;
    flex-direction: column;
}

.treatment-ser-item .quantity .quantity-text span:nth-child(1) {
    font-size: 9px;
    line-height: 12px;
    font-weight: 700;
    color: var(--text-hover);
}

.treatment-ser-item .quantity .quantity-text span:nth-child(2) {
    font-size: 11px;
    line-height: 12px;
    font-weight: bold;
    color: var(--text-black);
}

.my-ser-book__list-item {
    background-color: var(--bgWhite);
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
    height: 176px;
}

/* .my-ser-book__list li:not(:last-child) {
  border-bottom: 1px solid var(--bgGray);
} */
.__list-item__cnt {
    width: calc(100% - 234px);
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.__list-item__cnt .top {
    display: flex;
    flex-direction: column;
}

.__list-item__cnt .name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--text-black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.__list-item__cnt .org-name {
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: var(--purple);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.__list-item__cnt .quantity {
    margin-top: 20px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: var(--grey);
    width: fit-content;
}

.__list-item__cnt .quantity-text {
    display: flex;
    flex-direction: column;
}

.__list-item__cnt .quantity-text span:nth-child(1) {
    font-size: 9px;
    line-height: 12px;
    font-weight: 700;
    color: var(--text-hover);
}

.__list-item__cnt .quantity-text span:nth-child(2) {
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
    color: var(--text-black);
}

.__list-item__cnt .bottom-price {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--purple);
}

.__list-item__cnt .bottom-btn span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--green);
    margin-right: 24px;
}

.__list-item__cnt .bottom-btn button {
    background-color: var(--purple);
    font-size: 14px;
    line-height: 20px;
    color: var(--bgWhite);
    padding: 4px 8px;
    border: none;
    outline: none;
    border-radius: 14px;
}

/* popup notification */
.my-ser-popup {
    width: 490px;
    padding: 36px;
    background-color: var(--bgWhite);
}

.my-ser-popup__title h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--purple);
    margin-left: 8px;
}

.my-ser-popup__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text-black);
    text-align: center;
    margin-top: 16px;
    margin-bottom: 12px;
}

/* service bottom */
.my-ser-bot {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.my-ser-bot__up {
    height: 100vh;
    background-color: var(--bgWhite);
    transition: all 0.3s;
}

.my-ser-bot__cnt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.my-ser-bot__cnt-count {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: var(--purple);
}

.my-ser-bot__check {
    margin-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    height: 100%;
}

.my-ser-bot__check-left {
    padding-right: 18px;
    width: 50%;
}

.my-ser-bot__check-left .title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: var(--purple);
    padding-bottom: 8px;
    border-bottom: solid 1px var(--purple);
}

.my-ser-choose {
    padding: 24px;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.my-ser-choose li {
    margin: 12px 0px;
}

.my-ser-choose__item {
    padding: 8px;
    border-radius: 12px;
    align-items: flex-start;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 12%);
}

.my-ser-choose__item img {
    width: 154px;
    height: 108px;
    border-radius: 12px;
    object-fit: cover;
}

.my-ser-choose__item-cnt {
    width: calc(100% - 154px);
    padding-left: 12px;
}

.my-ser-choose__item-cnt span:nth-child(1) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.my-ser-choose__item-cnt span:nth-child(1),
.my-ser-choose__item-cnt span:nth-child(2) {
    font-size: 16px;
    list-style: 18px;
    font-weight: 700;
    color: var(--text-black);
}

.my-ser-choose__item-cnt span:nth-child(2) {
    font-size: 14px;
    color: var(--purple);
}

.my-ser-bot__check-right {
    padding-left: 18px;
    width: 50%;
    height: 100%;
}

.my-ser-bot__check-right .choose-time {
    display: flex;
    gap: 0 24px;
    width: 100%;
}

.my-ser-bot__check-right .choose-branch,
.my-ser-bot__check-right .choose-time .date {
    cursor: pointer;
    padding: 8px 16px;
    box-shadow: inset 0px 2px 6px rgba(113, 97, 186, 0.2);
    border-radius: 8px;
}

.my-ser-bot__check-right .choose-time .date,
.my-ser-bot__check-right .choose-time .time {
    width: 100%;
}

.my-ser-bot__check-right .choose-branch span,
.my-ser-bot__check-right .choose-time .date span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--text-hover);
}

/* .my-ser-bot__check-right .choose-time .date span {
    color: #fff;
} */

.my-ser-bot__check-right .choose-branch,
.my-ser-bot__check-right .choose-time .date {
    position: relative;
}

.my-ser-bot__check-right .choose-time {
    z-index: 0;
}

.ser-choose-br {
    position: absolute;
    width: 100%;
    height: max-content;
    background-color: var(--bgWhite);
    top: 50px;
    margin-left: -16px;
    z-index: 1;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 12%);
    transition: all 0.3s;
}

.ser-choose-br li {
    width: 100%;
    margin: 8px 0px;
    padding: 4px 8px;
    border-bottom: solid 1px var(--text-hover);
    display: flex;
    flex-direction: column;
    color: var(--text-black);
}

.ser-choose-date,
.ser-choose-time {
    background-color: var(--bgWhite);
    padding: 12px;
    position: absolute;
    width: 100%;
    left: 3px;
    top: 50px;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 12%);
    transition: all 0.3s;
}

.ser-choose-date {
    padding: unset;
}

.my-ser-submit {
    width: 100%;
    height: calc(100% - 160px);
    margin-top: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 12%);
}

/* my-ser-submit */
.my-ser-submit {
    padding: 24px;
}

.my-ser-submit__title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    color: var(--purple);
    text-align: center;
}

.my-ser-submit .time {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    border-bottom: solid 1px var(--bgGray);
}

.my-ser-submit .section__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text-hover);
    margin-bottom: 8px;
}

.my-ser-submit .info {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: var(--text-black);
}

.my-ser-submit__note {
    margin-top: 24px;
    padding: 12px;
    width: 100%;
    height: 150px;
    box-shadow: 0px 6px 15px #dcd8ee;
    border-radius: 18px;
    background-color: var(--bgWhite);
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text-black);
    resize: none;
}

/* ser-book-noti */
.ser-book-noti {
    padding: 36px 48px;
    background-color: var(--bgWhite);
}

.ser-book-noti__title {
    margin-top: 12px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: var(--purple);
}

.my-ser-submit h3 {
    font-size: 15px;
    margin-left: 4px;
}

.ser-book-noti__text {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--text-black);
    margin-top: 8px;
}

.my-ser-bot__check {
    align-items: unset;
}

.my-ser-popup {
    max-width: 430px;
    border-radius: 24px;
    overflow: hidden;
    margin: 0 auto;
}

.date-time_expired {
    font-size: 11px;
    line-height: 18px;
    font-weight: 600;
    color: var(--text-black);
}

.quantity-text__time-ex {
    background-color: #ffe3d2;
    padding: 2px 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    border-radius: 4px;
    color: var(--orange);
}

.treatment_card_item_bot {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    height: 0px;
    overflow-y: hidden;
    transition: var(--trans);
}

.treatment_card_item_bot_btn {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-black);
    background-color: #ABD373;
}

/* res-tablet */
/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .my-ser {
        display: flex;
        gap: 0 24px;
        padding: 64px 16px;
    }

    .my-ser__left {
        width: 33.33%;
    }

    .my-ser__right {
        width: calc(100% - 33.33%);
        padding: 0;
    }

    .my-ser-book__cnt {
        padding: 0;
    }

    .my-ser-bot__cnt {
        padding: 0 16px;
    }

    .my-ser__left-title {
        font-size: 20px;
    }

    .my-ser-book__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px 16px;
    }

    .my-ser-book__list-item {
        flex-direction: column;
        height: unset;
        gap: 16px 0;
        padding: unset;
        overflow: hidden;
    }

    .order-item__img {
        height: 180px;
    }

    .__list-item__cnt {
        width: 100%;
        padding: 0 24px 24px 24px;
        flex: 1;
        flex-direction: column;
        display: flex;
    }

    .__list-item__cnt .quantity {
        margin-top: 8px;
    }

    .order-item__img {
        border-radius: 24px 24px 0 0;
    }

    .my-ser-book__list li {
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .my-ser-book__list:nth-child(1) {
        margin: 16px 0 0 0;
    }

    .my-ser .bottom {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px 0;
        margin-top: 8px;
        /* margin-top: auto; */
        flex-shrink: 0;
    }

    .order-item__img {
        filter: unset;
    }

    .my-ser-bot__up {
        padding: 18px 16px;
    }

    .my-ser-bot__check {
        align-items: unset;
    }

    .my-ser-submit__btn {
        margin-top: 16px;
    }

    .my-ser-choose__item-cnt {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px 0;
    }

    .my-ser-choose {
        padding: 0;
    }

    .my-ser-choose__item {
        box-shadow: unset;
        border-bottom: 1px solid var(--bgGray);
        border-radius: unset;
        padding: 0 0 16px 0;
    }

    .my-ser-bot .choose-time {
        flex-direction: column;
    }

    .my-ser-bot .choose-time .date {
        width: 100%;
    }

    .my-ser-bot .ser-choose-date,
    .my-ser-bot .ser-choose-time {
        z-index: 10;
        left: 0;
    }
}

/* res-mobile */
/* Mobile */
@media only screen and (max-width: 1023px) {
    .my-ser-book__list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .my-ser-book__list li {
        width: 100%;
    }

    .treat-card-item {
        padding: 12px;
        box-shadow: var(--box-shadow);
        background-color: var(--bgWhite);
        border-radius: 0px;
        margin-bottom: 12px;
        width: 100vw !important;
        margin-left: -12px !important;
        margin-bottom: 10px !important;
    }
    .treat-card-item__dot{
       display: none;
    }

    .treat-card-item__head .org-name {
        font-size: 12px;
        line-height: 14px;
    }

    .treatment-ser-item__cnt .ser-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    .treat-card-item__head .time,
    .treat-card-item__head .code {
        font-size: 10px;
        line-height: 12px;
    }

    .treat-card-item__ser-count .title {
        font-size: 10px;
    }

    .my-ser {
        flex-direction: column;
        padding: 64px 4px 12px 12px;
    }

    .my-ser-book {
        padding-bottom: 84px;
    }

    .my-ser-book__cnt-head {
        margin-top: -10px;
    }

    .treat-card-item__book {
        font-size: 12px;
        line-height: 22px;
        margin: -14px 0px 0px 0px;
    }

    .my-ser__left {
        padding: 12px;
        width: 100%;
        z-index: 3;
        border-radius: 12px;
        height: 50px;
        overflow: hidden;
        transition: all 0.3s !important;
    }

    .my-ser__left-title {
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .my-ser__left-title img:nth-child(1) {
        display: none;
    }

    .my-ser__org {
        padding-left: 0px;
    }

    .my-ser__right {
        padding-left: 0px;
        width: 100%;
    }

    .my-ser-book__cnt {
        padding: 0px;
    }

    .my-ser .my-ser-book__cnt-head {
        margin-bottom: -22px;
        padding-bottom: 26px;
        border: none;
    }

    .my-ser-book__cnt-head-btn span {
        display: none;
    }

    .my-ser-book__cnt-head-btn {
        justify-content: center;
    }

    .my-ser-book__list-item {
        position: relative;
        border-radius: 8px;
        height: 144px;
        padding: 16px;
    }

    .my-ser-book__list-item .bottom {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 0 16px 16px 16px;
    }

    .treatment-ser-item__img {
        width: 72px;
        height: 72px;
    }

    .quantity-text__time-ex {
        font-size: 10px;
        line-height: 12px;
    }

    .__list-item__cnt {
        width: calc(100% - 62px);
        padding-left: 8px;
    }

    .__list-item__cnt .name {
        font-size: 16px;
        line-height: 16px;
    }

    .__list-item__cnt .quantity {
        margin-top: 6px;
    }

    .my-ser-popup {
        max-width: calc(100% - 48px);
    }

    .my-ser-bot__cnt {
        padding: 0 24px;
    }

    .my-ser-bot__cnt button {
        width: 100%;
    }

    .my-ser-bot__check {
        padding: 0 20px 20px 20px;
        flex-direction: column;
        height: calc(100vh - 55px);
        overflow-y: scroll;
        margin-top: 8px;
    }

    .my-ser-bot__check-left,
    .my-ser-bot__check-right {
        width: 100%;
        padding: 0;
    }

    .my-ser-bot__check-left {
        height: max-content;
    }

    .my-ser-bot .choose-time .date {
        width: 100%;
    }

    .my-ser-bot .choose-time {
        flex-direction: column;
        gap: 16px 0;
    }

    .my-ser-bot .ser-choose-date,
    .my-ser-bot .ser-choose-time {
        z-index: 10;
        left: 0;
    }

    .my-ser-bot__up {
        z-index: 4;
        padding: 10px 0px;
    }

    .my-ser-submit__btn {
        margin-top: 16px;
    }

    .my-ser-choose__item-cnt {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px 0;
        height: max-content;
    }

    .my-ser-choose__item img {
        width: 92px;
        height: 64px;
    }

    .my-ser-choose {
        padding: 16px 0px;
        height: fit-content;
    }

    .my-ser-choose ul {
        display: flex;
        flex-direction: column;
        gap: 18px 0;
    }

    .my-ser-choose li {
        margin: 0;
    }

    .my-ser-submit__title {
        font-size: 20px;
    }

    .my-ser-submit .section__title {
        font-size: 14px;
    }

    .time .info {
        font-size: 14px;
    }

    .my-ser-book__cnt-head-title {
        font-size: 18px;
    }

    .treatment-ser-item__cnt {
        width: calc(100% - 112px);
    }

    .my-ser-bot {
        margin-top: 0px;
    }

    .my-ser-bot button {
        background-color: var(--white);
        color: var(--purple);
    }
    .treatment_card_item_bot_btn{
        width: 100%;
    }
}

/* custom back drop  */
.back-drop_layout {
    content: "";
    position: fixed;
    inset: 0;
    z-index: 0;
    opacity: 0;
    top: "80px";
    display: none;
}

.back-drop_layout.open {
    opacity: 1;
    top: 50px;
    display: block;
}